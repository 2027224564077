import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect'

import Header from './header';
import Footer from './footer';
import MainContent from './mainContent';
import SideBar from './sidebar';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const initialState = {
  isSideBarOpen: !isMobile,
}

const Dashboard = () => {
  const [state, setState] = useState(initialState)
  const location = useLocation()
  const navigate = useNavigate()

  // subscribing to all states
  useSelector((state) => localStorage.setItem(APP_NAME, JSON.stringify(state)))

  const match = matchPath({ path: '/dashboard', caseSensitive: false, end: true }, location.pathname)

  // used for re-directing the parent
  // matchPath is required to match the base paths
  // location.search to carry over the search which comes 
  useEffect(() => {
    if (match)
      navigate('/dashboard/clearout_users' + location.search, { replace: true })
  }, [navigate, location.pathname, location.search, match])

  const onSidebarToggleButtonClick = (e) => {
    e.preventDefault()

    setState((prevState) => ({
      isSideBarOpen: !prevState.isSideBarOpen
    }))
  }

  return (
    <div
      id='dashboard'
      className='wrapper'
    >
      <Header
        toggle={onSidebarToggleButtonClick}
        isSideBarOpen={state.isSideBarOpen}
      />
      <SideBar isSideBarOpen={state.isSideBarOpen} />
      <MainContent />
      <Footer />
    </div>
  )
}

export default Dashboard