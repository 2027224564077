import React from 'react';
import { HashLoader } from 'react-spinners'

const RouteLoader = ({ size = 50, color='#712cf9', loading = true, speedMultiplier = 1 }) => {

  console.log('LOADER CALLED!!!')
  return (
    <HashLoader
      size={size}
      color={color}
      loading={loading}
      speedMultiplier={speedMultiplier}
    />
  )
}

export default RouteLoader