import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

import Breadcrumbs from '../../widgets/breadcrumb';
import ApiService from '../../helpers/apiservice';
import * as Constants from '../../helpers/constants';
import TabsInfo from './tabs_info';
import LoadingWrapper from '../widgets/loading';
import * as Helpers from '../../helpers/helper'
import * as IndividualUserConstants from './helpers/constant'
import * as UserInfoFormatters from './helpers/user_info_formatters'
import QuickLinks from './quick_links'
import * as UserInfoPageApiHelpers from './helpers/user_info_apis'
import ExtraUserInfo from './extra_user_info';

import './../../static/css/loader.css'
import './../../static/css/profile.css'

const initialState = {
  userData: null,
  isRadarModalOpen: false,
  isChecked: true,
  additionalUserData: {},
  isLoading: false,
}

const OrganizationInfo = ({ client }) => {
  const [state, setState] = useState(initialState)

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const user_id = queryParams.get('org_id');

  const getClearoutUserInfo = useCallback(() => {
    let requestBody = {
      co_user_id: user_id,
      co_org_id: user_id,
      client_secret: Constants.CLIENT_SECRET
    }
    let body = _.pick(requestBody, UserInfoPageApiHelpers.API_HELPERS[client].ORG_INFO.API_BODY)

    ApiService[UserInfoPageApiHelpers.API_HELPERS[client].ORG_INFO.API_NAME](body)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          setState(prevState => ({ ...prevState, userData: response.data, }))
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }, [user_id, client])

  const getClearoutUserAdditionalInfo = useCallback(() => {
    let requestBody = {
      co_user_id: user_id,
      cop_user_id: user_id,
      client_secret: Constants.CLIENT_SECRET
    }
    let body = _.pick(requestBody, UserInfoPageApiHelpers.API_HELPERS[client].ADDITIONAL_INFO.API_BODY)

    ApiService[UserInfoPageApiHelpers.API_HELPERS[client].ADDITIONAL_INFO.API_NAME](body)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          setState(prevState => ({ ...prevState, additionalUserData: response.data }))
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }, [user_id, client])

  const getUserInfo = useCallback(() => {
    getClearoutUserInfo()
    getClearoutUserAdditionalInfo()
  }, [getClearoutUserInfo, getClearoutUserAdditionalInfo])

  useEffect(() => {
    getUserInfo()
  }, [getUserInfo])

  const getLeftTable = () => {
    const leftTable = IndividualUserConstants.ORG_BASIC_USER_INFO[client].LEFT_TABLE

    return (
      <>
        {leftTable.map((opts, idx) => {
          const accessorValue = _.get(state.userData, opts.accessorKey);
          return (
            <div
              key={idx}
              className='d-flex align-items-center flex-wrap'
            >
              {UserInfoFormatters[opts.formatter]({
                client,
                value: accessorValue,
                userData: state.userData,
                additionalData: state.additionalUserData,
                user_id,
                getUserInfo
              })}
            </div>
          )
        })}
      </>
    )
  }

  const getRightTable = () => {
    const rightTable = IndividualUserConstants.ORG_BASIC_USER_INFO[client].RIGHT_TABLE

    return (
      <>
        {rightTable.map((opts, idx) => {
          const accessorValue = _.get(state.userData, opts.accessorKey);
          return (
            <div
              key={idx}
              className='d-flex align-items-center'
            >
              {UserInfoFormatters[opts.formatter]({
                client,
                value: accessorValue,
                userData: state.userData,
                additionalData: state.additionalUserData,
                user_id,
                getUserInfo,
                isChecked: state.isChecked,
              })}
            </div>
          )
        })}
      </>
    )
  }

  const getBadges = () => {
    let tagsToShow = []
    const isPaid = state.userData && state.userData.tags ? Helpers.getPaidTag(state.userData) : null;

    if (isPaid)
      tagsToShow.push('Paid')

    let userRole = state.userData.user_role
    if (userRole === 'analyst')
      userRole = 'executive'

    userRole = Helpers.capitalizeFirstLetter(userRole)

    tagsToShow.push(userRole)

    let backgrounds = ['primary', 'success', 'danger', 'warning', 'info']

    return (
      <div className='d-flex gap-1'>
        {
          tagsToShow.map(tag => {
            return (
              <Badge
                key={tag}
                bg={backgrounds[_.random(0, backgrounds.length - 1)]}
              >{tag}
              </Badge>
            )
          })
        }
      </div>
    )
  }

  if (!state.userData) {
    return <p>No Data</p>
  }

  return (
    <div className='container-fluid overflow-auto'>
      {state.isLoading ? (
        <div className="loader-container">
          <LoadingWrapper
            type="bars"
            color="#007bff"
            height={50}
            width={50}
          />
        </div>
      ) : null}
      <div
        className='container-fluid'
      >
        <Breadcrumbs options={Constants.BREAD_CRUMB_OPTIONS.clearout_org} />
        <div className="info-section-container">
          <div className="basic-info-section mb-3 p-3">
            <div className='d-flex justify-content-center pt-2'>
              <h3
                className='text-primary'
                key={state.userData.status}
              >
                &nbsp;&nbsp;{state.userData.name ? Helpers.capitalizeFirstLetter(state.userData.name) : state.userData.email}&nbsp;
              </h3>
            </div>
            <div className="d-flex justify-content-center">
              {getBadges()}
            </div>
            <div className="d-flex justify-content-end gap-3 py-2">
              <QuickLinks
                client={client}
                userData ={state.userData}
              />
            </div>
            <div className="d-flex user-info pt-2">
              <div className="col-md-5 p-0">
                {getLeftTable()}
              </div>
              <div className="col-md-4 p-0">
                {getRightTable()}
              </div>
            </div>
          </div>
          <div className='px-3 extra-info-section'>
            <ExtraUserInfo
              userData={state.userData}
              client={client}
              isChecked={state.isChecked}
              additionalData={state.additionalUserData}
              getUserInfo={getUserInfo}
            />
          </div>
        </div>
      </div>
      {/* tab section */}
      <div className='p-3'>
        <TabsInfo
          userData={state.userData}
          getUserInfo={getUserInfo}
          client={client}
        />
      </div>
    </div>
  )
}
export default OrganizationInfo
