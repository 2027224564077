import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import _ from 'lodash'

import * as IndividualUserConstants from '../helpers/constant'
import * as UserInfoFormatters from '../helpers/user_info_formatters'
import PlanTab from './plans'
import InvoicesAndReceipts from './invoices_receipts'
import Analytics from './analytics';
import * as Constants from '../../../helpers/constants'
import SubscriptionHistory from './subscription_history'


const initialState = {
  key: 'credits_limits',
}

const TabsInfo = ({ userData, getUserInfo, client }) => {
  const [state, setState] = useState(initialState)

  const handleTabSelect = (key) => {
    setState((prevState) => ({ ...prevState, key: key }));
  };

  const getCreditsSection = () => {
    const creditsSection = IndividualUserConstants.CREDITS_LIMITS_TAB[client].CREDITS_SECTION

    return (
      <div className="container overflow-hidden">
        {creditsSection.map((opts, idx) => {
          const accessorValue = _.get(userData, opts.accessorKey);
          return (
            <div
              key={idx}
              className='d-flex justify-content-between'
            >
              <p>{opts.label}</p>
              {UserInfoFormatters[opts.formatter]({
                client,
                value: accessorValue,
                userData,
                getUserInfo
              })}
            </div>
          )
        })}
      </div>
    )
  }

  const getLimitsSection = () => {
    const limitsSection = IndividualUserConstants.CREDITS_LIMITS_TAB[client].LIMITS_SECTION

    return (
      <div className="container overflow-hidden">
        {limitsSection.map((opts, idx) => {
          const accessorValue = _.get(userData, opts.accessorKey);
          return (
            <div
              key={idx}
              className='d-flex justify-content-between'
            >
              {UserInfoFormatters[opts.formatter]({
                client,
                value: accessorValue,
                userData,
                getUserInfo,
                label: opts.label
              })}
            </div>
          )
        })}
      </div>
    )
  }

  // since there is no way to load only active tab content
  // we have switch case to load only active tab content
  const getTabContent = (tab) => {

    // checking if the tab is active
    if (tab !== state.key)
      return null

    switch (tab) {
      case 'credits_limits': {
        return (
          <div
            className="container overflow-hidden"
          >
            <div className="row gx-5">
              <div className="col">
                <div className="p-2 border credits-limits-section h-100">
                  <h5 className='text-center'>Credits</h5>
                  <div className='d-flex justify-content-between'>
                    {getCreditsSection()}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="p-2 border credits-limits-section h-100">
                  <h5 className='text-center'>Limits</h5>
                  <div className='d-flex justify-content-between'>
                    {getLimitsSection()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

      case 'plans': {
        return <PlanTab
          userData={userData}
          getUserInfo={getUserInfo}
          client={client}
        />
      }

      case 'subscription_history': {
        return <SubscriptionHistory
          userData={userData}
          getUserInfo={getUserInfo}
          client={client}
        />
      }

      case 'invoices_receipts': {
        return <InvoicesAndReceipts
          data={userData}
          getUserInfo={getUserInfo}
          client={client}
        />
      }

      case 'analytics': {
        return <Analytics />
      }

      default:
        return null
    }
  }

  return (
    <>
      <Tabs
        activeKey={state.key}
        onSelect={handleTabSelect}
        className="mb-3"
        fill
      >
        {
          IndividualUserConstants.TAB_SECTION[client]
            .map(tab =>
              Constants.DISPLAY_USER_ROLES[userData.user_role]
                .profile_tabs
                .includes(tab) ?
                <Tab
                  key={tab}
                  eventKey={IndividualUserConstants.TAB_KEY_AND_LABLE_MAPPING[tab].key}
                  title={IndividualUserConstants.TAB_KEY_AND_LABLE_MAPPING[tab].label}
                >
                  {getTabContent(tab)}
                </Tab>
                : null
            )
        }
      </Tabs>
    </>
  )
}

export default TabsInfo
