import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Container, Row, Form } from 'react-bootstrap';
import _ from 'lodash';

import ApiService from '../../helpers/apiservice';
import * as Constants from '../../helpers/constants';
import AddAbuseModel from './add_abuse_entry';
import SignUpAbuseLimiter from './signup_abuse_limiter';
import AbuseListTable from './abuse_list_table';
import * as UserInfoPageApiHelpers from '../individual_user_info/helpers/user_info_apis';

const initialState = {
  abuse_list: { nodes: [] },
  tableDataLength: 1,
  sort: {
    'created_on': 'desc'
  },
};

const ManageAbuse = ({ client }) => {
  const [state, setState] = useState(initialState);
  const abortControllerRef = useRef(null); // Reference to store the AbortController

  const getAbuseList = useCallback((skip, limit, searchText, sorting) => {
    // Abort any ongoing request before making a new one
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the new request
    const controller = new AbortController();
    abortControllerRef.current = controller;

    sorting = state.sorting;
    let requestBody = {
      skip: skip,
      limit: limit,
      search: searchText,
      sort: sorting,
      client_secret: Constants.CLIENT_SECRET,
    };
    
    let body = _.pick(requestBody, UserInfoPageApiHelpers.API_HELPERS[client].GET_ABUSE_LIST.API_BODY);
    
    ApiService[UserInfoPageApiHelpers.API_HELPERS[client].GET_ABUSE_LIST.API_NAME](body, controller.signal)
      .then((res) => {
        if (res.status === Constants.CO_API.SUCCESS) {
          setState(prevState => ({
            ...prevState,
            abuse_list: { nodes: res.data.data },
            tableDataLength: res.data.data.count
          }));
        } else if (res.status === Constants.CO_API.FAILED) {
          console.log('Failed to get abuse list');
        }
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.log(err);
        }
      });
  }, [state.sorting, client]);

  // Handle component unmounting to abort ongoing requests
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const onSearchChange = (e) => {
    getAbuseList(0, 50, e.target.value, {
      'created_on': 'desc'
    });
  };

  const clientDisplayName = Constants.CLIENTS[client.toUpperCase()].DISPLAY_NAME;

  return (
    <Container fluid>
      <h1 className='header'>{clientDisplayName} Manage Abuse</h1>
      <Row className='align-items-center pt-2'>
        <div className='col-md-8'>
          <div className='d-flex gap-2'>
            <AddAbuseModel
              getAbuseList={getAbuseList}
              client={client}
            />
            <SignUpAbuseLimiter client={client} />
          </div>
        </div>
        <div className='col-md-4 pt-2'>
          <Form.Control
            type='search'
            placeholder='Search...'
            onChange={onSearchChange}
          />
        </div>
      </Row>
      <div className='pt-3'>
        <AbuseListTable
          getAbuseList={getAbuseList}
          data={state.abuse_list}
          client={client}
          setState={setState}
        />
      </div>
    </Container>
  );
};

export default ManageAbuse;
