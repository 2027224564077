import React, { useCallback, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import _ from 'lodash'

import PrdouctTypeDropdown from './dropdowns/product_type_dropdwon'
import CreatePlanProduct from './forms/create_plan'
import CreateAddonProduct from './forms/create_addon'

const initialStates = {
  product_type: 'plan',
  currency_code: 'usd',
  credits: 3000,
  product_name: '',
  amount: 0,
  is_custom: true,
  recurring: true,
  can_display: false,
  interval_count: 1,
  interval_unit: 'month',
  interval_times: Number.POSITIVE_INFINITY,
  dropdown_options: [],
  credit_interval_count: 1,
  credit_interval_unit: 'month',
  reset_credits: false,
  limit_interval_count: 1,
  limit_interval_unit: 'month',
}

const CreateProductForm = ({ getProductToView, resetProduct }) => {

  const [state, setState] = useState(initialStates)

  useEffect(() => {
    setState(_.assign(initialStates, { product_type: state.product_type }))
    resetProduct()
  }, [state.product_type, resetProduct])

  const onStateChange = useCallback((stateKey, stateValue) => {
    setState((prevState) => ({
      ...prevState,
      [stateKey]: stateValue
    }))
  }, [])

  const onProductViewClicked = () => {
    let reqBody = _.cloneDeep(state)
    reqBody.dropdown_options = JSON.stringify(state.dropdown_options)
    getProductToView(reqBody)
  }

  return (
    <div className="product-create-form">
      <PrdouctTypeDropdown onStateChange={onStateChange} />
      {
        state.product_type === 'plan'
          ? <CreatePlanProduct
            onStateChange={onStateChange}
            {...state}
          />
          : <CreateAddonProduct
            onStateChange={onStateChange}
            {...state}
          />
      }
      <Button
        className="view-product-btn"
        onClick={onProductViewClicked}
      >
        View {_.startCase(state.product_type)}
      </Button>
    </div>
  )
}

export default CreateProductForm