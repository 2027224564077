
export const API_HELPERS = {
  clearout: {
    ADDITIONAL_INFO: {
      API_NAME: 'getClearoutUserProfileAdditionalInfo',
      API_BODY: ['co_user_id']
    },
    ADD_BONUS: {
      API_NAME: 'giveBonusClearoutUser',
      API_BODY: ['co_user_id', 'user_id', 'credits', 'description']
    },
    RESET_BONUS: {
      API_NAME: 'resetBonusClearoutUser',
      API_BODY: ['co_user_id', 'user_id', 'description']
    },
    RECEIPTS: {
      API_NAME: 'listInvoices',
      API_BODY: ['co_user_id']
    },
    GENERATE_RECEIPT: {
      API_NAME: 'clearoutMarkAsPaid',
      API_BODY: ['co_user_id', 'user_id', 'amount', 'payment_type', 'credits', 'payment_gateway', 'description', 'client_secret']
    },
    DELETE_ACCOUNT: {
      API_NAME: 'deleteClearoutUser',
      API_BODY: ['co_user_id', 'reason', 'user_id']
    },
    ORG_INFO: {
      API_NAME: 'getOrganizationInfo',
      API_BODY: ['co_org_id']
    },
    ORG_ADD_BONUS: {
      API_NAME: 'giveBonusClearoutOrg',
      API_BODY: ['co_org_id', 'user_id', 'credits', 'description']
    },
    ORG_RESET_BONUS: {
      API_NAME: 'resetClearoutOrgCredits',
      API_BODY: ['co_org_id', 'user_id', 'description']
    },
    ORG_UPDATE_API_RATE_LIMIT: {
      API_NAME: 'updateRateLimitOrg',
      API_BODY: ['api_rate_limits', 'co_org_id', 'reason', 'user_id']
    },
    ORG_REMOVE_API_RATE_LIMIT: {
      API_NAME: 'removeRateLimitOrg',
      API_BODY: ['api_names', 'co_org_id', 'reason', 'user_id']
    },
    ORG_UPDATE_DAILY_LIMIT: {
      API_NAME: 'updateOrgDailyLimit',
      API_BODY: ['co_org_id', 'user_id', 'daily_verify_limit', 'reason']
    },
    ORG_ADD_DAILY_LIMIT: {
      API_NAME: 'clearoutOrgAddDailyLimit',
      API_BODY: ['co_org_id', 'user_id', 'allowed_daily_limit', 'reason']
    },
    ORG_ADD_SEATS: {
      API_NAME: 'updateOrgAllocateSeat',
      API_BODY: ['co_org_id', 'user_id', 'member_seat_count', 'reason', 'host']
    },
  },
  clearoutphone: {
  }
}