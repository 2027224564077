import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import _ from 'lodash';

import ApiService from '../../helpers/apiservice';
import * as Constants from '../../helpers/constants';
import AbuseListTable from './abuse_list_table';
import * as UserInfoPageApiHelpers from '../individual_user_info/helpers/user_info_apis';
import FiltersComponent from '../widgets/filters';
import * as FiltersConstants from '../widgets/filters/constants';

import '../../static/css/userlist.css';

const initialState = {
  abuse_list: { nodes: [] },
  tableDataLength: 1,
  searchText: '',
  sort: {
    'updatedAt': 'desc'
  },
  filters: {
    date_range: {
      filter_label: 'date_range',
      value: {
        key: 'created_on',
        preset: 'ps_last_7_days_including_today'
      }
    },
    task: {
      filter_label: 'Task',
      label: 'All',
      value: ''
    }
  },
};

const filterParams = (filters) => {
  let queryFilter = {
    task: filters.task?.value || null,
    name: filters.name?.value || '',
    date_range: filters.date_range?.value || null,
  };
  if (queryFilter.status === 'all') {
    queryFilter.status = null;
  }
  return queryFilter;
};

const UpdateHistory = ({ client }) => {
  const [state, setState] = useState(initialState);
  const abortControllerRef = useRef(null); // Reference to store the AbortController

  const getAbuseList = useCallback((skip, limit, searchText) => {
    // Abort any ongoing request before making a new one
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new AbortController for the new request
    const controller = new AbortController();
    abortControllerRef.current = controller;

    let requestBody = {
      skip: skip,
      limit: limit,
      search: searchText,
      sort: state.sort,
      filters: { ...filterParams(state.filters) },
      client_secret: Constants.CLIENT_SECRET,
    };
    let body = _.pick(requestBody, UserInfoPageApiHelpers.API_HELPERS[client].GET_ABUSE_UPDATE_ACTIVITY.API_BODY);

    ApiService[UserInfoPageApiHelpers.API_HELPERS[client].GET_ABUSE_UPDATE_ACTIVITY.API_NAME](body, controller.signal)
      .then((res) => {
        if (res.status === Constants.CO_API.SUCCESS) {
          const updatedData = res.data.data.map(v => {
            v.id = v.id || _.uniqueId('abuse_');
            return v;
          });

          setState(prevState => ({
            ...prevState,
            abuse_list: { nodes: updatedData },
            tableDataLength: updatedData.length
          }));
        } else {
          console.log('Failed to get abuse update history list');
        }
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.log(err);
        }
      });
  }, [state.filters, state.sort, client]);

  // Handle component unmounting to abort ongoing requests
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const onSearch = (event) => {
    const newSearchText = event.target.value;
    setState(prevState => ({ ...prevState, searchText: newSearchText }));
    getAbuseList(0, 50, newSearchText);
  };

  const clearSearch = () => {
    setState(prevState => ({ ...prevState, searchText: '' }));
    getAbuseList(0, 50, '');
  };

  const onSetFilter = (filters) => {
    setState(prevState => ({ ...prevState, filters: { ...filters } }));
  };

  const clientDisplayName = Constants.CLIENTS[client.toUpperCase()].DISPLAY_NAME;

  return (
    <Container fluid>
      <h1 className='header'>{clientDisplayName} Abuse Update History</h1>
      <div>
        <FiltersComponent
          filterState={state.filters}
          onSearch={onSearch}
          clearSearch={clearSearch}
          searchValue={state.searchText}
          onSetFilters={onSetFilter}
          filters={FiltersConstants.ABUSE_UPDATE_HISTORY[client].filters}
          filtersToInclude={FiltersConstants.FILTERS_TO_INCLUDE[client].ABUSE_LOGS}
          resetFilters={FiltersConstants.ABUSE_UPDATE_HISTORY[client].onResetFilters}
          skipFilter={['date_range', 'org_filter']}
          client={client}
        />
      </div>
      <div className='pt-3'>
        <AbuseListTable
          getAbuseList={getAbuseList}
          data={state.abuse_list}
          client={client}
          setState={setState}
        />
      </div>
    </Container>
  );
};

export default UpdateHistory;
