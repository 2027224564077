import React from 'react'
import { Navbar } from 'react-bootstrap'

const Footer = () => {

  return (
    <footer
      id='footer'
      className='main-footer'
    >
      <Navbar
        className="d-flex p-1"
        fixed="bottom"
        as={'div'}
      >
        <strong>Copyright © 2017-2024 &nbsp;
          <a
            href="https://clearout.io"
            rel='noreferrer noopener'
            target='_blank'
            className='text-warning'
          >
            Clearout
          </a>.
        </strong> &nbsp;All rights
        reserved.
      </Navbar>

    </footer>
  )
}

export default Footer