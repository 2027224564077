import React, { useState } from 'react'
import { Button } from 'react-bootstrap'

import RadarSettingsModal from './settings_modal'

const initialState = {
  showModal: false,
}

const RadarSettings = () => {
  const [state, setState] = useState(initialState)

  const handleOnClick = () => {
    setState(prevState => ({ ...prevState, showModal: true }))
  }

  const closeModal = () => {
    setState(initialState)
  }

  return (
    <>
      <Button
        variant="primary"
        onClick={handleOnClick}
      >
        Update Radar Settings
      </Button>
      {
        state.showModal
          ? <RadarSettingsModal
            showModal={state.showModal}
            closeModal={closeModal}
          />
          : null
      }
    </>
  )
}

export default RadarSettings
