import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import _ from 'lodash'

import showToastNotification from '../../../../widgets/toastify'
import ApiService from '../../../../../helpers/apiservice'
import * as UserInfoPageApiHelpers from '../../../helpers/user_info_apis'
import * as Constants from '../../../../../helpers/constants'
import CustomDropdown from '../../../../widgets/custom_dropdown'

const initialState = {
  stripe_account: _.first(Constants.PAYMENT_ACCOUNTS.STRIPE),
  paypal_account: _.first(Constants.PAYMENT_ACCOUNTS.PAYPAL)
}

const PaymentAccountModel = ({
  showModal,
  closeModal,
  getUserInfo,
  userData,
  client
}) => {
  initialState.stripe_account = _.find(Constants.PAYMENT_ACCOUNTS.STRIPE, { value: userData.payment.stripe.account_name })
  initialState.paypal_account = _.find(Constants.PAYMENT_ACCOUNTS.PAYPAL, { value: userData.payment.paypal.account_name })

  const [state, setState] = useState(initialState)

  const updateUsersDefaultPaymentAccount = () => {

    let requestBody = {
      co_user_id: userData.user_id,
      stripe_account: state.stripe_account.value,
      paypal_account: state.paypal_account.value
    }

    let body = _.pick(requestBody, UserInfoPageApiHelpers.API_HELPERS[client].UPDATE_USERS_DEFAULT_PAYMENT_ACCOUNT.API_BODY)
    setState(prevState => ({ ...prevState, isApiCallInProgress: true }))
    ApiService[UserInfoPageApiHelpers.API_HELPERS[client].UPDATE_USERS_DEFAULT_PAYMENT_ACCOUNT.API_NAME](body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          showToastNotification({
            message: response.message || 'Failed to udpate account',
            isSuccess: false,
          })
        } else {
          getUserInfo()
          handleCloseModal()
          showToastNotification({
            message: response.message || 'Payment accounts updated',
            isSuccess: true,
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setState(prevState => ({ ...prevState, isApiCallInProgress: false }))
      })
  }

  const handleCloseModal = () => {
    setState(initialState)
    closeModal()
  }

  const onDropdownSelect = (selectedOption, e) => {
    let { name } = e

    setState((prevState) => ({
      ...prevState,
      [name]: selectedOption
    }))
  }

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title className='fs-6 fw-bold'>
          Change Default Payment Account for {userData.name || userData.email}&apos;s account
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body-bg-color'>
        <div className='d-flex flex-column gap-2'>
          <div className='payment-account-dropdown paypal-account'>
            <label>Select PayPal Account</label>
            <CustomDropdown
              name='paypal_account'
              options={Constants.PAYMENT_ACCOUNTS.PAYPAL}
              placeholder={'Select PayPal Account'}
              value={state.paypal_account}
              onSelectOption={onDropdownSelect}
            />
          </div>
          <div className='payment-account-dropdown stripe-account'>
            <label>Select Stripe Account</label>
            <CustomDropdown
              name='stripe_account'
              options={Constants.PAYMENT_ACCOUNTS.STRIPE}
              placeholder={'Select Stripe Account'}
              value={state.stripe_account}
              onSelectOption={onDropdownSelect}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={updateUsersDefaultPaymentAccount}
          disabled={state.isApiCallInProgress}
        >
          Update Accounts
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PaymentAccountModel