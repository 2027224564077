
export const API_HELPERS = {
  clearout: {
    USER_LIST: {
      API_NAME: 'getUserList',
      API_BODY: ['skip', 'limit', 'search', 'sort', 'filters']
    },
    USER_LIST_DOWNLOAD: {
      API_NAME: 'getClearoutUsersList',
      BODY: ['skip', 'limit', 'search', 'sort', 'filters', 'download']
    },
    CREATE_USER: {
      API_NAME: 'createClearoutUser',
      BODY: ['email', 'phone']
    },
    ORGANIZATION_NAMES: {
      API_NAME: 'getOrganizationNames',
      BODY: ['limit', 'skip', 'sort']
    },
    COUPONS_LIST: {
      API_NAME: 'listCoupons',
      API_BODY: ['skip', 'limit', 'search', 'sort']
    },
  },
  clearoutphone: {
    USER_LIST: {
      API_NAME: 'getClearoutPhoneUsersList',
      API_BODY: ['skip', 'limit', 'search', 'sort', 'filters', 'client_secret']
    },
    USER_LIST_DOWNLOAD: {
      API_NAME: 'getClearoutPhoneUsersList',
      BODY: ['skip', 'limit', 'search', 'sort', 'filters', 'download', 'client_secret']
    },
    CREATE_USER: {
      API_NAME: 'createClearoutPhoneUser',
      BODY: ['email', 'phone']
    },
    COUPONS_LIST: {
      API_NAME: 'listCopCoupons',
      API_BODY: ['skip', 'limit', 'search', 'sort','client_secret']
    },
  }
}