import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';

import ApiService from '../../helpers/apiservice';
import * as Constants from '../../helpers/constants';
import showToastNotification from '../widgets/toastify'
import * as UserInfoPageApiHelpers from './helpers/user_info_apis'

const initialState = {
  selectedApi: null,
  reason: '',
  isApiCallInProgress: false,
  showModal: false,
}

function RemoveRateLimitModel({ data, getUserInfo, client }) {
  const [state, setState] = useState(initialState)
  const id = useSelector(state => state.user.id);
  const userName = useSelector(state => state.user.name);

  const options = Constants.OPTIONS[client]

  const getApiNames = () => {
    let api_names = []
    if (client === 'clearout') {
      switch (state.selectedApi.value) {
        case 'Instant Email Verify':
          api_names = [Constants.API_RATELIMIT_SERVICES.INSTANT_EMAIL_VERIFY_LIMIT]
          break
        case 'Instant Email Finder':
          api_names = [Constants.API_RATELIMIT_SERVICES.INSTANT_EMAIL_FINDER_LIMIT]
          break
        case 'All':
          api_names = [Constants.API_RATELIMIT_SERVICES.INSTANT_EMAIL_VERIFY_LIMIT, Constants.API_RATELIMIT_SERVICES.INSTANT_EMAIL_FINDER_LIMIT]
          break
        default:
          api_names = []
      }
    } else {
      switch (state.selectedApi.value) {
        case 'Instant Phone Number Validator':
          api_names = [Constants.COP_API_RATELIMIT_SERVICES.INSTANT_PHONENUMBER_VALIDATE_LIMIT]
          break
        case 'All':
          api_names = [Constants.COP_API_RATELIMIT_SERVICES.INSTANT_PHONENUMBER_VALIDATE_LIMIT]
          break
        default:
          api_names = []
      }
    }

    return api_names
  }

  const handleSaveChanges = () => {
    let requestBody = {
      api_names: getApiNames(),
      co_user_id: data.user_id,
      cop_user_id: data.user_id,
      reason: state.reason,
      user_id: id,
      client_secret: Constants.CLIENT_SECRET,
      crm_user: {
        name: userName,
        user_id: id,
      },
    };

    let body = _.pick(requestBody, UserInfoPageApiHelpers.API_HELPERS[client].REMOVE_API_RATE_LIMIT.API_BODY)
    setState(prevState => ({ ...prevState, isApiCallInProgress: true }));
    ApiService[UserInfoPageApiHelpers.API_HELPERS[client].REMOVE_API_RATE_LIMIT.API_NAME](body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          showToastNotification({
            message: 'Failed',
            isSuccess: false,
          });
        } else if (response.status === Constants.CO_API.SUCCESS) {
          getUserInfo()
          handleCloseModal()
          setState((prevState) => ({
            ...prevState,
            showModal: false,
          }));
          showToastNotification({
            message: 'API Rate Limit Removed Successfully',
            isSuccess: true,
          });
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setState(prevState => ({ ...prevState, isApiCallInProgress: false }));
      })
  };

  const handleSelect = (selectedOption) => {
    setState(prevState => ({ ...prevState, selectedApi: selectedOption }))
  }

  const handleReason = (e) => {
    setState(prevState => ({ ...prevState, reason: e.target.value }))
  }

  const handleShowModal = () => {
    setState(prevState => ({ ...prevState, showModal: true }));
  };

  const handleCloseModal = () => setState(initialState)

  return (
    <>
      <Button
        size="sm"
        variant='outline-danger'
        onClick={handleShowModal}
      >
        <FontAwesomeIcon icon="fa-solid fa-xmark" />
      </Button>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>
            Remove API Rate Limit of {data.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <Form>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>API:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Select
                    options={options}
                    isClearable={true}
                    searchable={true}
                    placeholder="Select Service"
                    value={state.selectedApi}
                    onChange={handleSelect}
                  />
                </div>
              </Form.Group>
            </div>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Description:</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Form.Control
                    type="text"
                    placeholder="Enter Description"
                    value={state.reason}
                    maxLength={50}
                    onChange={handleReason}
                  />
                </div>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            onClick={handleSaveChanges}
            disabled={!state.selectedApi || !state.reason || state.isApiCallInProgress}
          >
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RemoveRateLimitModel;
