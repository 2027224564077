import { ajax } from './helper'

class ApiService {
  async login(body) {
    const loginUrl = 'api/v1/user/login'
    return await ajax(loginUrl, 'POST', body);
  }
  async getUserList(body, signal) {
    const userListUrl = 'api/v1/clearout/user_list';
    return await ajax(userListUrl, 'POST', body, true, signal);
  }
  async clearoutGraphInsights(body) {
    const clearoutGraphInsightsUrl = 'api/v1/clearout/insights/graph';
    return await ajax(clearoutGraphInsightsUrl, 'POST', body);
  }
  async getClearoutUsersActivities(body, cancelToken) {
    const getClearoutUsersListUrl = 'api/v1/clearout/user_activities';
    return await ajax(getClearoutUsersListUrl, 'POST', body, cancelToken);
  }
  async createClearoutUser(body) {
    const createClearoutUserUrl = 'api/v1/clearout/createuser';
    return await ajax(createClearoutUserUrl, 'POST', body);
  }
  async getOrganizationNames(body) {
    const getOrganizationListsUrl = 'api/v1/clearout/organization/names';
    return await ajax(getOrganizationListsUrl, 'POST', body);
  }
  async getCleaoutUserInfo(body) {
    const getClearoutUserInfo = 'api/v1/clearout/user_info';
    return await ajax(getClearoutUserInfo, 'POST', body);
  }
  async clearoutUpdateUserEmail(body) {
    const clearoutUpdateTimezoneUrl = 'api/v1/clearout/email_edit';
    return await ajax(clearoutUpdateTimezoneUrl, 'POST', body);
  }
  async clearoutUpdateTimezone(body) {
    const clearoutUpdateTimezoneUrl = 'api/v1/clearout/update_timezone';
    return await ajax(clearoutUpdateTimezoneUrl, 'POST', body);
  }
  async giveBonusClearoutUser(body) {
    const giveBonusClearoutUserUrl = 'api/v1/clearout/bonus';
    return await ajax(giveBonusClearoutUserUrl, 'POST', body);
  }
  async resetBonusClearoutUser(body) {
    const resetBonusClearoutUserUrl = 'api/v1/clearout/credits/reset';
    return await ajax(resetBonusClearoutUserUrl, 'POST', body);
  }
  async toggleRadar(body) {
    const changeRadarStatus = 'api/v1/clearout/user/toggle_radar';
    return await ajax(changeRadarStatus, 'POST', body)
  }
  async getClearoutUserProfileAdditionalInfo(body) {
    const getClearoutUserProfileAdditionalInfo = 'api/v1/clearout/user_profile_additional_info'
    return await ajax(getClearoutUserProfileAdditionalInfo, 'POST', body)
  }
  async updateRateLimitIndividual(body) {
    const updateRateLimitIndividualUrl = 'api/v1/clearout/user/update_api_ratelimit';
    return await ajax(updateRateLimitIndividualUrl, 'POST', body);
  }
  async removeRateLimitIndividual(body) {
    const removeRateLimitIndividualUrl = 'api/v1/clearout/user/remove_api_ratelimit';
    return await ajax(removeRateLimitIndividualUrl, 'POST', body);
  }
  async clearoutAddDailyLimit(body) {
    const clearoutAddDailyLimitUrl = 'api/v1/clearout/set_daily_limit';
    return await ajax(clearoutAddDailyLimitUrl, 'POST', body);
  }
  async clearoutUsersRemoveDailyLimit(body) {
    const clearoutUsersRemoveDailyLimitUrl = 'api/v1/clearout/remove_daily_limit';
    return await ajax(clearoutUsersRemoveDailyLimitUrl, 'POST', body);
  }
  async clearoutUpdateDailyLimit(body) {
    const clearoutUpdateDailyLimitUrl = 'api/v1/clearout/update_daily_limit';
    return await ajax(clearoutUpdateDailyLimitUrl, 'POST', body);
  }
  async clearoutUserSubsList(body) {
    const clearoutUserSubsListUrl = 'api/v1/clearout/subscription/list';
    return await ajax(clearoutUserSubsListUrl, 'POST', body);
  }
  async listInvoices(co_user_id) {
    const listInvoicesUrl = 'api/v1/clearout/receipts';
    return await ajax(listInvoicesUrl, 'POST', co_user_id);
  }
  async clearoutMarkAsPaid(body) {
    const clearoutMarkAsPaidUrl = 'api/v1/clearout/mark_as_paid';
    return await ajax(clearoutMarkAsPaidUrl, 'POST', body);
  }

  async spokeslyMarkAsPaid(body) {
    const spokeslyMarkAsPaidUrl = 'api/v1/spokesly/mark_as_paid';
    return await ajax(spokeslyMarkAsPaidUrl, 'POST', body);
  }
  async logoutAllSessionOfClearoutUser(body) {
    const logoutAllSessionOfClearoutUserUrl = 'api/v1/clearout/logout';
    return await ajax(logoutAllSessionOfClearoutUserUrl, 'POST', body);
  }
  async blockClearoutUser(body) {
    const blockUserApiUrl = 'api/v1/clearout/user/block';
    return await ajax(blockUserApiUrl, 'POST', body);
  }
  async unblockClearoutUser(body) {
    const unblockUserApiUrl = 'api/v1/clearout/user/unblock';
    return await ajax(unblockUserApiUrl, 'POST', body);
  }
  async deleteClearoutUser(body) {
    const deleteUserApiUrl = 'api/v1/clearout/user/delete';
    return await ajax(deleteUserApiUrl, 'POST', body);
  }
  async resetTokens(body) {
    const resetTokensUrl = 'api/v1/clearout/reset_tokens';
    return await ajax(resetTokensUrl, 'POST', body);
  }
  async getClearoutUsersList(body, signal) {
    const getClearoutUsersListUrl = 'api/v1/clearout/user_list';
    return await ajax(getClearoutUsersListUrl, 'POST', body, true, signal);
  }
  async removeEmailFromCache(queryParams) {
    const removeEmailFromCacheUrl = 'api/v1/clearout/email_verify/remove_email_from_cache';
    const url = `${removeEmailFromCacheUrl}?${new URLSearchParams(queryParams).toString()}`;
    return await ajax(url, 'DELETE');
  }
  async updateDisposableDomains(body) {
    const updatedisposable = 'api/v1/clearout/sysprop/update_disposable_domain';
    return await ajax(updatedisposable, 'PUT', body);
  }
  async overAllUsageReports(queryParams) {
    const usageReportsUrl = 'api/v1/clearout/report/all'
    const url = `${usageReportsUrl}?${new URLSearchParams(queryParams).toString()}`;
    return await ajax(url, 'GET')
  }
  async emailVerifyReports(queryParams) {
    const allUsersEmailVerifyList = 'api/v1/clearout/report/email_verify'
    const url = `${allUsersEmailVerifyList}?${new URLSearchParams(queryParams).toString()}`;
    return await ajax(url, 'GET')
  }
  async emailFinderReports(queryParams) {
    const allUsersEmailFinderList = 'api/v1/clearout/report/email_finder'
    const url = `${allUsersEmailFinderList}?${new URLSearchParams(queryParams).toString()}`;
    return await ajax(url, 'GET')
  }
  async prospectReports(queryParams) {
    const allUsersProspectList = 'api/v1/clearout/report/prospect'
    const url = `${allUsersProspectList}?${new URLSearchParams(queryParams).toString()}`;
    return await ajax(url, 'GET')
  }
  async accountStatusUpdateClearoutUser(body) {
    const userAccountStatusUpdateUrl = 'api/v1/clearout/user/account_status_update';
    return await ajax(userAccountStatusUpdateUrl, 'POST', body);
  }
  async getClearoutUserStatuses(body) {
    const coUserStatusApiUrl = 'api/v1/clearout/user/statuses';
    return await ajax(coUserStatusApiUrl, 'POST', body);
  }

  async getCoProductList(queryParams) {
    const getCoProductListUrl = 'api/v1/clearout/product/list'
    let query = new URLSearchParams(queryParams).toString()

    const url = getCoProductListUrl + '?' + query
    return await ajax(url, 'GET')
  }

  async getCoAddonProductInfo(queryParams) {
    const getCoAddonProductInfoUrl = 'api/v1/clearout/product/addon_info'
    let query = new URLSearchParams(queryParams).toString()

    const url = getCoAddonProductInfoUrl + '?' + query
    return await ajax(url, 'GET')
  }

  async getProduct(queryParams) {
    const getProductUrl = 'api/v1/clearout/product/view'
    let query = new URLSearchParams(queryParams)
    const url = getProductUrl + '?' + query
    return await ajax(url, 'GET')
  }

  async createProduct(body) {
    const createProductUrl = 'api/v1/clearout/product/create'
    return await ajax(createProductUrl, 'POST', body)
  }

  async udpateUserPaymentSettings(body) {
    const udpateUserPaymentSettingsUrl = 'api/v1/clearout/user/payment_settings_update'
    return await ajax(udpateUserPaymentSettingsUrl, 'PATCH', body)
  }

  async attachProduct(body) {
    const attachProductUrl = 'api/v1/clearout/product/attach'
    return await ajax(attachProductUrl, 'POST', body)
  }

  async detachProduct(body) {
    const detachProductUrl = 'api/v1/clearout/product/detach'
    return await ajax(detachProductUrl, 'POST', body)
  }

  async getOrganizationLists(body, signal) {
    const getOrganizationListsUrl = 'api/v1/clearout/organization/list';
    return await ajax(getOrganizationListsUrl, 'POST', body, true, signal);
  }
  async getOrganizationInfo(body) {
    const getOrganizationInfoUrl = 'api/v1/clearout/organization_info';
    return await ajax(getOrganizationInfoUrl, 'POST', body);
  }
  async giveBonusClearoutOrg(body) {
    const giveBonusClearoutOrgUrl = 'api/v1/clearout/organization_bonus';
    return await ajax(giveBonusClearoutOrgUrl, 'POST', body);
  }

  async getUsersPlansAndActiveSubscriptions(queryParams) {
    const getProductUrl = 'api/v1/clearout/plan/list'
    let query = new URLSearchParams(queryParams)
    const url = getProductUrl + '?' + query
    return await ajax(url, 'GET')
  }


  async resetClearoutOrgCredits(body) {
    const resetClearoutOrgCreditsUrl =
      'api/v1/clearout/organization/credits/reset';
    return await ajax(resetClearoutOrgCreditsUrl, 'POST', body);
  }

  async updateRateLimitOrg(body) {
    const updateRateLimitOrgUrl = 'api/v1/clearout/organization/update_api_ratelimit';
    return await ajax(updateRateLimitOrgUrl, 'POST', body);
  }

  async removeRateLimitOrg(body) {
    const removeRateLimitOrgUrl = 'api/v1/clearout/organization/remove_api_ratelimit';
    return await ajax(removeRateLimitOrgUrl, 'POST', body);
  }

  async updateOrgDailyLimit(body) {
    const updateOrgDailyLimitUrl =
      'api/v1/clearout/organization/update_daily_limit';
    return await ajax(updateOrgDailyLimitUrl, 'POST', body);
  }

  async clearoutOrgAddDailyLimit(body) {
    const clearoutOrgAddDailyLimitUrl =
      'api/v1//clearout/organization/update_allowed_daily_limit';
    return await ajax(clearoutOrgAddDailyLimitUrl, 'POST', body);
  }

  async updateOrgAllocateSeat(body) {
    const updateOrgDailyLimitUrl =
      'api/v1/clearout/organization/update_member_seat_limit';
    return await ajax(updateOrgDailyLimitUrl, 'POST', body);
  }

  async getSystemRadarSettings(queryParams) {
    const getSystemRadarSettingsUrl = 'api/v1/clearout/radar/settings_list'
    const url = `${getSystemRadarSettingsUrl}?${new URLSearchParams(queryParams).toString()}`
    return await ajax(url, 'GET')
  }

  async updateSystemRadarSettings(body) {
    const updateSystemRadarSettingsUrl = 'api/v1/clearout/radar/settings_update'
    return await ajax(updateSystemRadarSettingsUrl, 'PUT', body)
  }

  async udpateUserDefaultPaymentAccount(body) {
    const udpateUserDefaultPaymentAccountgsUrl = 'api/v1/clearout/user/payment_account_update'
    return await ajax(udpateUserDefaultPaymentAccountgsUrl, 'PATCH', body)
  }


  async getAbuseListWrtCountry(body) {
    const addAbuseListsUrl = 'api/v1/clearout/abuse_list/country';
    return await ajax(addAbuseListsUrl, 'POST', body);
  }

  async addAbuseListEntry(body) {
    const addAbuseListsUrl = 'api/v1/clearout/abuse_list/add';
    return await ajax(addAbuseListsUrl, 'POST', body);
  }

  async signUpAbuseLimiter(body) {
    const signUpAbuseLimiterUrl = 'api/v1/clearout/signup_abuse_limiter';
    return await ajax(signUpAbuseLimiterUrl, 'POST', body);
  }

  async getAbuseList(body, signal) {
    const getAbuseListsUrl = 'api/v1/clearout/abuse_list';
    return await ajax(getAbuseListsUrl, 'POST', body, true, signal);
  }

  async listCoupons(body, signal) {
    const listCouponsUrl = 'api/v1/clearout/coupon/list';
    return await ajax(listCouponsUrl, 'POST', body, true, signal);
  }

  async createCoupon(body) {
    const createCouponUrl = 'api/v1/clearout/coupon/create';
    return await ajax(createCouponUrl, 'POST', body);
  }

  async editCoupon(body) {
    const editCouponUrl = 'api/v1/clearout/coupon/edit';
    return await ajax(editCouponUrl, 'POST', body);
  }
  async updateAbuseListEntry(body) {
    const updateAbuseListsUrl = 'api/v1/clearout/abuse_list/update';
    return await ajax(updateAbuseListsUrl, 'POST', body);
  }

  async getClearoutAbuseActivityLogs(body, signal) {
    const getClearoutAbuseActivityUrl = 'api/v1/clearout/abuse_activity';
    return await ajax(getClearoutAbuseActivityUrl, 'POST', body, true, signal);
  }

  async getClearoutAbuseUpdateLogs(body, signal) {
    const getClearoutAbuseUpdateActivityUrl = 'api/v1/clearout/abuse_update_activity';
    return await ajax(getClearoutAbuseUpdateActivityUrl, 'POST', body, true, signal);
  }

  async getUserSubscriptions(queryParams) {
    const getUserSubscriptionsUrl = 'api/v1/clearout/user_subscription/list'
    let query = new URLSearchParams(queryParams).toString()

    const url = getUserSubscriptionsUrl + '?' + query
    return await ajax(url, 'GET')
  }


  //cop api's
  async getClearoutPhoneUsersList(body, signal) {
    const getClearoutUsersListUrl = CLEAROUT_PHONE_BASE_URL + 'v1/user/list';
    return await ajax(
      getClearoutUsersListUrl,
      'POST',
      body,
      false,
      signal,
      false
    );
  }

  async createClearoutPhoneUser(body) {
    const createClearoutPhoneUserUrl = CLEAROUT_PHONE_BASE_URL + 'v1/user/createuser'
    return await ajax(createClearoutPhoneUserUrl, 'POST', body, false, null, false)
  }

  async getCopUserInfo(body) {
    const getClearoutUserInfo = CLEAROUT_PHONE_BASE_URL + 'v1/user/info';
    return await ajax(
      getClearoutUserInfo,
      'POST',
      body,
      false,
      null,
      false
    );
  }

  async getClearoutPhoneUserProfileAdditionalInfo(body) {
    const createClearoutPhoneUserUrl = CLEAROUT_PHONE_BASE_URL + 'v1/user/user_profile_additional_info'
    return await ajax(createClearoutPhoneUserUrl, 'POST', body, false, null, false)
  }

  async giveBonusCopUser(body) {
    const giveBonusCopUserUrl = CLEAROUT_PHONE_BASE_URL + 'v1/user/bonus';
    return await ajax(
      giveBonusCopUserUrl,
      'POST',
      body,
      false,
      null,
      false
    );
  }

  async resetBonusCopUser(body) {
    const resetBonusCopUserUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/credits/reset';
    return await ajax(
      resetBonusCopUserUrl,
      'POST',
      body,
      false,
      null,
      false
    );
  }

  async clearoutPhoneUpdateTimezone(body) {
    const clearoutUpdateTimezoneUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/timezone/edit';
    return await ajax(
      clearoutUpdateTimezoneUrl,
      'POST',
      body,
      false,
      null,
      false
    );
  }

  async copResetTokens(body) {
    const resetTokensUrl = CLEAROUT_PHONE_BASE_URL + 'v1/user/reset_tokens';
    return await ajax(resetTokensUrl, 'POST', body, false, null, false);
  }

  async logoutCopAllSessionOfClearoutUser(body) {
    const logoutAllSessionOfClearoutUserUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/sessions/deactivate';
    return await ajax(
      logoutAllSessionOfClearoutUserUrl,
      'POST',
      body,
      false,
      null,
      false
    );
  }

  async clearoutPhoneAddDailyLimit(body) {
    const clearoutAddDailyLimitUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/set_daily_limit';
    return await ajax(
      clearoutAddDailyLimitUrl,
      'POST',
      body,
      false,
      null,
      false
    );
  }

  async clearoutPhoneUsersRemoveDailyLimit(body) {
    const clearoutUsersRemoveDailyLimitUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/remove_daily_limit';
    return await ajax(
      clearoutUsersRemoveDailyLimitUrl,
      'POST',
      body,
      false,
      null,
      false
    );
  }

  async clearoutPhoneUpdateDailyLimit(body) {
    const clearoutUpdateDailyLimitUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/update_daily_limit';
    return await ajax(
      clearoutUpdateDailyLimitUrl,
      'POST',
      body,
      false,
      null,
      false
    );
  }

  async updateRateLimitForCOPIndividual(body) {
    const updateRateLimitForCOPIndividualUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/crm/update_api_ratelimit';
    return await ajax(updateRateLimitForCOPIndividualUrl, 'POST', body, false, null, false);
  }

  async removeRateLimitForCOPIndividual(body) {
    const removeRateLimitForCOPIndividualUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/crm/remove_api_ratelimit';
    return await ajax(removeRateLimitForCOPIndividualUrl, 'POST', body, false, null, false);
  }

  async listCopInvoices(body) {
    const listInvoicesUrl = CLEAROUT_PHONE_BASE_URL + 'v1/user/receipts';
    return await ajax(listInvoicesUrl, 'POST', body, false, null, false);
  }

  async clearoutPhoneMarkAsPaid(body) {
    const clearoutPhoneMarkAsPaidUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/mark_as_paid';
    return await ajax(
      clearoutPhoneMarkAsPaidUrl,
      'POST',
      body,
      false,
      null,
      false
    );
  }
  async clearoutPhoneUpdateUserEmail(body) {
    const clearoutPhoneUpdateEmailUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/crm/email_edit';
    return await ajax(
      clearoutPhoneUpdateEmailUrl,
      'POST',
      body,
      false,
      null,
      false
    );
  }

  async blockClearoutPhoneUser(body) {
    const blockClearoutPhoneUserUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/block_user';
    return await ajax(
      blockClearoutPhoneUserUrl,
      'POST',
      body,
      false,
      null,
      false
    );
  }

  async unBlockClearoutPhoneUser(body) {
    const unBlockClearoutPhoneUserUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/unblock_user';
    return await ajax(
      unBlockClearoutPhoneUserUrl,
      'POST',
      body,
      false,
      null,
      false
    );
  }

  async deleteClearoutPhoneUser(body) {
    const deleteClearoutPhoneUserUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/user/user_delete';
    return await ajax(
      deleteClearoutPhoneUserUrl,
      'POST',
      body,
      false,
      null,
      false
    );
  }

  async listCopCoupons(body, signal) {
    const listCouponsUrl = CLEAROUT_PHONE_BASE_URL + 'v1/coupon/list';
    return await ajax(listCouponsUrl, 'POST', body, false, signal, false);
  }

  async createCopCoupon(body) {
    const createCouponUrl = CLEAROUT_PHONE_BASE_URL + 'v1/coupon/create';
    return await ajax(createCouponUrl, 'POST', body, false, null, false);
  }

  async editCopCoupon(body) {
    const editCouponUrl = CLEAROUT_PHONE_BASE_URL + 'v1/coupon/edit';
    return await ajax(editCouponUrl, 'POST', body, false, null, false);
  }

  async getCopAbuseList(body, signal) {
    const getAbuseListsUrl = CLEAROUT_PHONE_BASE_URL + 'v1/crm/abuse_list';
    return await ajax(
      getAbuseListsUrl,
      'POST',
      body,
      false,
      signal,
      false
    );
  }

  async addCopAbuseListEntry(body) {
    const addAbuseListsUrl = CLEAROUT_PHONE_BASE_URL + 'v1/crm/abuse_list/add';
    return await ajax(
      addAbuseListsUrl,
      'POST',
      body,
      false,
      null,
      false
    );
  }

  async getCopAbuseListWrtCountry(body) {
    const addAbuseListsUrl = CLEAROUT_PHONE_BASE_URL + 'v1/crm/abuse_list/country';
    return await ajax(
      addAbuseListsUrl,
      'POST',
      body,
      false,
      null,
      false
    );
  }

  async copSignUpAbuseLimiter(body) {
    const signUpAbuseLimiterUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/crm/signup_abuse_limiter';
    return await ajax(
      signUpAbuseLimiterUrl,
      'POST',
      body,
      false,
      null,
      false
    );
  }

  async updateCopAbuseListEntry(body) {
    const updateAbuseListsUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/crm/abuse_list/update';
    return await ajax(
      updateAbuseListsUrl,
      'POST',
      body,
      false,
      null,
      false
    );
  }

  async getClearoutPhoneAbuseActivityLogs(body, signal) {
    const updateAbuseListsUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/crm/abuse_activity';
    return await ajax(
      updateAbuseListsUrl,
      'POST',
      body,
      false,
      signal,
      false
    );
  }

  async getClearoutPhoneAbuseUpdateLogs(body, signal) {
    const updateAbuseActivityUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/crm/abuse_update_activity';
    return await ajax(
      updateAbuseActivityUrl,
      'POST',
      body,
      false,
      signal,
      false
    );
  }

  async getCopUsersActivities(body, signal) {
    const getCopUsersActivitiesUrl =
      CLEAROUT_PHONE_BASE_URL + 'v1/crm/user_activities';
    return await ajax(
      getCopUsersActivitiesUrl,
      'POST',
      body,
      false,
      signal,
      false
    );
  }
}

export default new ApiService()