import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import ApiService from '../../helpers/apiservice';
import showToastNotification from '../widgets/toastify';
import * as Constants from '../../helpers/constants'
import * as UserInfoPageApiHelpers from './helpers/user_info_apis'

const initialState = {
  description: '',
  showModal: false,
  isApiCallInProgress: false
}

function ResetBonusModel({ getUserInfo, name, client, userData }) {
  const [state, setState] = useState(initialState)
  const id = useSelector((state) => state.user.id);
  const userName = useSelector(state => state.user.name);

  const handleDescChange = (e) => {
    setState(prevState => ({ ...prevState, description: e.target.value }))
  }

  const handleSaveChanges = () => {
    let requestBody = {
      co_org_id: userData._id,
      user_id: id,
      description: state.description,
      client_secret: Constants.CLIENT_SECRET,
      crm_user_data: {
        name: userName
      },
      cop_user_id: userData.user_id,
      created_by: id
    }

    let body = _.pick(requestBody, UserInfoPageApiHelpers.API_HELPERS[client].ORG_RESET_BONUS.API_BODY)
    setState(prevState => ({ ...prevState, isApiCallInProgress: true }));

    ApiService[UserInfoPageApiHelpers.API_HELPERS[client].ORG_RESET_BONUS.API_NAME](body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          showToastNotification({
            message: 'Failed to reset',
            isSuccess: false,
          });
        } {
          (response.status === Constants.CO_API.SUCCESS)
          showToastNotification({
            message: 'Credits reset successfully',
            isSuccess: true,
          });
          setState(initialState)
          getUserInfo();
          setState((prevState) => ({
            ...prevState,
            showModal: false,
          }));
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setState(prevState => ({ ...prevState, isApiCallInProgress: false }));
      });
  };

  const handleShowModal = () => {
    setState(prevState => ({ ...prevState, showModal: true }));
  };

  const handleCloseModal = () => setState(initialState)

  return (
    <>
      <Button
        onClick={handleShowModal}
        variant='outline-primary'
        size="sm"
        className='sm-btns'
      >

        <FontAwesomeIcon
          icon={faSync}
        />
      </Button>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>Reset credit of {name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <Form>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Enter Desc :</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Form.Control
                    type="text"
                    placeholder="Enter Description"
                    value={state.description}
                    onChange={handleDescChange}
                    maxLength={50}
                  />
                </div>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={handleSaveChanges}
            disabled={!state.description || state.isApiCallInProgress}
          >
            Reset
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ResetBonusModel;
