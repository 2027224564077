import React from 'react'

import UserSubscriptions from './components/user_subscriptions'

const SubscriptionHistory = ({ userData, client }) => {

  return (
    <div className='subscription-history-tab'>
      <div className='subscription-history-tab__body'>
        <UserSubscriptions
          client={client}
          userData={userData}
        />
      </div>
    </div>
  )
}

export default SubscriptionHistory
