import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const initialState = {
  copied: false
}

function ClipboardCopy({ textToCopy }) {
  const [state, setState] = useState(initialState)

  const onCopy = () => {
    setState(prevState => ({ ...prevState, copied: true }))
    setTimeout(() => {
      setState(prevState => ({ ...prevState, copied: false }))
    }, 2000);
  };

  return (
    <div
      style={{
        cursor: 'pointer',
      }}
      title="Copy to Clipboard"
    >
      <CopyToClipboard
        text={textToCopy}
        onCopy={onCopy}
      >
        <span>
          {state.copied ? (
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={{
                color: 'green',
              }}
            />
          ) : (
            <FontAwesomeIcon icon="fa-regular fa-paste" />
          )}
        </span>
      </CopyToClipboard>
    </div>
  );
}

export default ClipboardCopy;
