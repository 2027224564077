import moment from 'moment'
export const COCRM_USER = 'user'

export const CO_API = {
  SUCCESS: 'success',
  FAILED: 'failed'
}

export const PAGE_SIZE = {
  values: [50, 100, 150, 200]
}
export const REGEX = {
  EMAIL_SYNTAX: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
}

export const API_OPTIONS = [
  { value: 'Instant Email Verify', label: 'Instant Email Verify' },
  { value: 'Instant Email Finder', label: 'Instant Email Finder' },
  { value: 'All', label: 'All API\'s' }
];

// export const API_RATELIMIT_SERVICES = {
//   INSTANT_EMAIL_VERIFY_LIMIT: 'instant_email_verify_limit',
//   INSTANT_EMAIL_FINDER_LIMIT: 'instant_email_finder_limit',
// }
export const ACCOUNT_DELETE_REASONS = {
  'data_privacy_concern': 'Data Privacy Concern',
  'alternative_found': 'Alternative Found',
  'temporary_suspension_required': 'Temporary Suspension Required',
  'service_no_longer_needed': 'Service No Longer Needed',
  'none_of_the_above': 'None of the Above'
}

export const SUBSCRIPTION_FILTERS = {
  status: [
    {
      value: 'active',
      label: 'Active'
    },
    {
      value: 'cancelled',
      label: 'Cancelled'
    }
  ],
  planType: [
    {
      value: 'yearly',
      label: 'Annually'
    },
    {
      value: 'monthly',
      label: 'Monthly'
    }
  ],
  userType: [
    {
      value: 'none',
      label: 'All'
    },
    {
      value: 'subscriber',
      label: 'Subscribed'
    },
    {
      value: 'paid',
      label: 'Paid'
    }
  ],
  userStatusType: [
    {
      value: 'none',
      label: 'All'
    },
    {
      value: 'active',
      label: 'Active'
    },
    {
      value: 'inactive',
      label: 'InActive'
    },
  ],
  orgUserStatusType: [
    {
      value: 'all',
      label: 'All'
    },
    {
      value: 'active',
      label: 'Active'
    },
    {
      value: 'inactive',
      label: 'InActive'
    },
    {
      value: 'new',
      label: 'Yet to Activate'
    },
    {
      value: 'removed',
      label: 'Removed'
    }
  ],
  role: [
    {
      value: 'none',
      label: 'All'
    },
    {
      value: 'owner',
      label: 'Owner'
    },
    {
      value: 'admin',
      label: 'Manager'
    },
    {
      value: 'analyst',
      label: 'Executive'
    },
  ]
}

//linechart
export const RESULTS = {
  total: {
    display_name: 'Total',
    type: 'value'
  },
  valid: {
    display_name: 'Valid',
    type: 'percentage'
  },
  invalid: {
    display_name: 'Invalid',
    type: 'percentage'
  },
  catch_all: {
    display_name: 'Catch All',
    type: 'percentage'
  },
  unknown: {
    display_name: 'Unknown',
    type: 'percentage'
  },
  duplicate: {
    display_name: 'Duplicate',
    type: 'percentage'
  },
  disposable: {
    display_name: 'Disposable',
    type: 'percentage'
  },
  role_account: {
    display_name: 'Role Account',
    type: 'percentage'
  },
  free_account: {
    display_name: 'Free Account',
    type: 'percentage'
  },
  profile: {
    display_name: 'Profile Account',
    type: 'percentage'
  },
  billable: {
    display_name: 'Billable',
    type: 'value'
  },
  deliverability_score: {
    display_name: 'Deliverability Score',
    type: 'value'
  },
  syntax_error: {
    display_name: 'Syntax Error',
    type: 'percentage'
  },
  billable_credits: {
    display_name: 'Credits Used (Billable)',
    type: 'value'
  },
  safe_to_send: {
    display_name: 'Safe To Send',
    type: 'percentage'
  }
}

export const SERVICE_BASED_REPORT_FIELDS = {
  verify: {
    total: {
      display_name: 'Total',
      type: 'value'
    },
    valid: {
      display_name: 'Valid',
      type: 'percentage'
    },
    invalid: {
      display_name: 'Invalid',
      type: 'percentage'
    },
    catch_all: {
      display_name: 'Catch All',
      type: 'percentage'
    },
    unknown: {
      display_name: 'Unknown',
      type: 'percentage'
    },
    duplicate: {
      display_name: 'Duplicate',
      type: 'percentage'
    },
    disposable: {
      display_name: 'Disposable',
      type: 'percentage'
    },
    role_account: {
      display_name: 'Role Account',
      type: 'percentage'
    },
    free_account: {
      display_name: 'Free Account',
      type: 'percentage'
    },
    profile: {
      display_name: 'Profile Account',
      type: 'percentage'
    },
    billable: {
      display_name: 'Billable',
      type: 'value'
    },
    deliverability_score: {
      display_name: 'Deliverability Score',
      type: 'value'
    },
    syntax_error: {
      display_name: 'Syntax Error',
      type: 'percentage'
    },
    billable_credits: {
      display_name: 'Credits Used (Billable)',
      type: 'value'
    },
    safe_to_send: {
      display_name: 'Safe To Send',
      type: 'percentage'
    }
  },
  finder: {
    total: {
      display_name: 'Total Request',
      type: 'value'
    },
    found: {
      display_name: 'Email Found',
      type: 'value'
    },
    not_found: {
      display_name: 'Email Not Found',
      type: 'value'
    },
    business: {
      display_name: 'Business',
      type: 'value'
    },
    role: {
      display_name: 'Role',
      type: 'value'
    },
    confidence_score: {
      display_name: 'Confidence Score',
      type: 'value'
    },
    billable: {
      display_name: 'Billable',
      type: 'value'
    }
  },
  prospect: {
    total_billable_email_contact: {
      display_name: 'Email Contacts',
      type: 'value'
    },
    total_billable_phone_contact: {
      display_name: 'Phone Contacts',
      type: 'value'
    },
    total_billable_lead: {
      display_name: 'Total Prospects',
      type: 'value'
    },
    total_credits_charged: {
      display_name: 'Total Credits',
      type: 'value'
    },
    email: {
      display_name: 'Email Lead',
      type: 'value'
    },
    phone: {
      display_name: 'Phone Lead',
      type: 'value'
    },
    billable: {
      display_name: 'Billable',
      type: 'value'
    }
  },
  overall: {
    ev_billable: {
      display_name: 'Email Verifier',
      type: 'value'
    },
    ef_billable: {
      display_name: 'Email Finder',
      type: 'value'
    },
    prospect_billable: {
      display_name: 'Prospect',
      type: 'value'
    }
  }
}
export const GRAPH_COLORS = ['#c6e377', '#f16f6f', '#75cac3', '#c0c0c0', '#970690']

export const GRAPH_AREA_COLORS = ['rgba(199, 227, 120, 0.20)', 'rgba(241, 111, 111, 0.20)', 'rgba(118, 203, 196, 0.20)', 'rgba(191, 191, 191, 0.20)', 'rgba(235, 193, 233, 0.2)']
/////////////////////
export const DATE_RANGE_PRESETS = {
  'Today': 'ps_today',
  'Yesterday': 'ps_yesterday',
  'This week (Mon - Today)': 'ps_this_week_mon_today',
  'Last 7 days (Including Today)': 'ps_last_7_days_including_today',
  'Last week (Mon - Sun)': 'ps_last_week_mon_sun',
  'This Month': 'ps_this_month',
  'Last Month': 'ps_last_month',
  'This Year': 'ps_this_year',
  'Last Year': 'ps_last_year'
};

export const RANGES = {
  'All': [moment(), moment()],
  'Today': [moment(), moment()],
  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  'This week (Mon - Today)': [moment().startOf('isoweek'), moment()],
  'Last 7 days (Including Today)': [moment().subtract(6, 'days'), moment()],
  'Last week (Mon - Sun)': [moment().subtract(1, 'week').startOf('isoweek'), moment().subtract(1, 'week').endOf('isoweek')],
  'This Month': [moment().startOf('month'), moment().endOf('month')],
  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
  'This Year': [moment().startOf('year'), moment()],
  'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
}

export const DATERANGE_PRESETS = {
  date_range_presets: [
    'ps_today',
    'ps_yesterday',
    'ps_this_week_mon_today',
    'ps_last_7_days_including_today',
    'ps_last_week_mon_sun',
    'ps_this_month',
    'ps_last_month',
    'ps_this_year',
    'ps_last_year'
  ],
  date_range_presets_definitions: {
    ps_today: {
      display_name: 'Today',
      value: 'ps_today'
    },
    ps_yesterday: {
      display_name: 'Yesterday',
      value: 'ps_yesterday'
    },
    ps_last_7_days_including_today: {
      display_name: 'Last 7 days (Including Today)',
      value: 'ps_last_7_days_including_today'
    },
    ps_this_week_mon_today: {
      display_name: 'This week (Mon - Today)',
      value: 'ps_this_week_mon_today'
    },
    ps_last_week_mon_sun: {
      display_name: 'Last week (Mon - Sun)',
      value: 'ps_last_week_mon_sun'
    },
    ps_this_month: {
      display_name: 'This Month',
      value: 'ps_this_month'
    },
    ps_last_month: {
      display_name: 'Last Month',
      value: 'ps_last_month'
    },
    ps_this_year: {
      display_name: 'This Year',
      value: 'ps_this_year'
    },
    ps_last_year: {
      display_name: 'Last Year',
      value: 'ps_last_year'
    },
    ps_last_24_hours: {
      display_name: 'Last 24 Hrs',
      value: 'ps_last_24_hours'
    }
  }
}

export const CLIENT_SECRET = '5b98682fe6339bf7706a3bf335b1568089d8a507c9a6a95ef60a3739b862a961'

export const CLIENTS = {
  CLEAROUT: {
    NAME: 'clearout',
    SHORT_NAME: 'co',
    DISPLAY_NAME: 'Clearout',
    DOWNLOAD_NAME: 'Download Clearout Users'
  },
  CLEAROUTPHONE: {
    NAME: 'clearoutphone',
    SHORT_NAME: 'cop',
    DISPLAY_NAME: 'Clearoutphone',
    DOWNLOAD_NAME: 'Download ClearoutPhone Users'
  },
  SPOKESLY: {
    NAME: 'spokesly',
    SHORT_NAME: 'spokesly',
    DISPLAY_NAME: 'Spokesly'
  },
  CLEAROUT_EUROPE: {
    NAME: 'clearout_europe',
    SHORT_NAME: 'co_eu',
    DISPLAY_NAME: 'Clearout EU'
  },
  CLEAROUT_ENTERPRISE: {
    NAME: 'clearout_epnterprise',
    SHORT_NAME: 'co_ep',
    DISPLAY_NAME: 'Clearout EP'
  },
  CLEAROUTS: {
    NAME: 'clearouts',
    SHORT_NAME: 'co',
    DISPLAY_NAME: 'Clearout',
    DOWNLOAD_NAME: 'Download Clearout Users'
  },
}

export const OPTIONS = {
  clearout: [
    { value: 'Instant Email Verify', label: 'Instant Email Verify' },
    { value: 'Instant Email Finder', label: 'Instant Email Finder' },
    { value: 'All', label: 'All API\'s' }
  ],
  clearoutphone: [
    { value: 'Instant Phone Number Validator', label: 'Instant Phone Number Validator' },
    { value: 'All', label: 'All API\'s' }
  ]
}

export const API_RATELIMIT_SERVICES = {
  INSTANT_EMAIL_VERIFY_LIMIT: 'instant_email_verify_limit',
  INSTANT_EMAIL_FINDER_LIMIT: 'instant_email_finder_limit',
}

export const COP_API_RATELIMIT_SERVICES = {
  INSTANT_PHONENUMBER_VALIDATE_LIMIT: 'instant_phonenumber_validate_limit',
}

export const BREAD_CRUMB_OPTIONS = {
  clearout: [
    { label: 'Clearout Users', href: '/dashboard/clearout_users', active: false },
    { label: 'Clearout Individual User Info', href: '/dashboard/clearout_individual_user_info', active: true },
  ],
  clearoutphone: [
    { label: 'ClearoutPhone Users', href: '/dashboard/clearoutphone_users', active: false },
    { label: 'ClearoutPhone Individual User Info', href: '/dashboard/clearoutphone_individual_user_info', active: true },
  ],
  clearout_org: [
    { label: 'Clearout Organization Users', href: '/dashboard/clearout_org_list', active: false },
    { label: 'Clearout Organization Info', href: '/dashboard/clearout_organization_info', active: true },
  ],
};

export const CURRENCY = {
  USD: {
    flag: require('../../src/static/svg/us_flag.svg'),
    symbol: '$',
    name: 'USD',
    locale: 'en-US'
  },
  INR: {
    flag: require('../../src/static/svg/in_flag.svg'),
    symbol: '₹',
    name: 'INR',
    locale: 'en-IN'
  }
}

export const DISPLAY_USER_ROLES = {
  admin: {
    value: 'admin',
    display_name: 'Manager',
    profile_tabs: ['credits_limits', 'analytics']
  },
  analyst: {
    value: 'analyst',
    display_name: 'Executive',
    profile_tabs: ['credits_limits', 'analytics']
  },
  owner: {
    value: 'owner',
    display_name: 'Owner',
    profile_tabs: ['credits_limits', 'plans', 'subscription_history', 'invoices_receipts', 'analytics']
  },

}

export const COUPON_TYPE = {
  standard: {
    display: 'Standard',
    value: 'standard'
  },
  discount: {
    display: 'Discount',
    value: 'discount'
  },
  bonus: {
    display: 'Bonus',
    value: 'bonus'
  }
};

export const COUPON_MIN_VALUE_CREDITS = 5000;
export const COUPON_MAX_VALUE_CREDITS = 10000;
export const COUPON_CREDITS_DEFAULT_VALUE = 100000;
export const COUPON_MAX_VALUE_FIXED = 10000;
export const COUPON_REDEEM_COUNT = 100000;
export const COUPON_MIN_FOR_FIXED = 21;
export const COUPON_SLAB_UNIT_PRICE = 0.001;
export const COUPON_SLAB_MAX_UNIT_PRICE = 0.005;
export const COUPON_DISCOUNT_FIXED_DEFAULT = 10;
export const COUPON_MAX_LENGTH = 15;
export const COUPON_MIN_LENGTH = 4;
export const MIN_VAL = 3000;
export const MAX_VAL = 5000000;
export const DURATION_VALUE = 1;


export const PAYMENT_ACCOUNTS = {
  PAYPAL: [
    { label: 'PayPal IND', value: 'paypal::india' },
    { label: 'PayPal US', value: 'paypal::us' },
  ],
  STRIPE: [
    { label: 'Stripe IND', value: 'stripe::india' },
    { label: 'Stripe US', value: 'stripe::us' },
  ],
}


export const COUPON_CONSTANTS = {
  NAME: 'Coupon name',
  COUPON_ID: 'Coupon id will used by customers for applying discount',
  REDEEM_COUNT: 'Number of times the customer can redeem the coupon',
  COUPON_TYPE: 'Coupon type : standard or discount or bonus coupon',
  DISCOUNT_TYPE: 'Select discount type',
  SLAB_MIN: 'Minimum credits to be purchased by user for applying the coupon',
  SLAB_MAX: 'Maximum credits to be purchased by user for applying the coupon',
  SLAB_VAL: 'Unit price for each credit',
  FIXED_MIN: 'Minimum billing amount for coupon to be applied',
  FIXED_MAX: 'Maximum billing amount for coupon to be applied',
  FIXED_VAL: 'Flat value which will be subtracted from the billing price',
  PERC_MIN: 'Minimum billing amount for the coupon to be applied',
  PERC_MAX: 'Maximum billing amount for the coupon to be applied',
  PERC_VAL: 'Percentage of amount to be decreased from billing price',
  CREDITS: 'Credits for assigning the user',
  USER_EMAIL: 'Select the user for this coupon',
  AFFILIATE: 'Enter the affiliate name',
  DAILY_VERIFY_LIMIT: 'Enter the daily limit for verification',
  DURATION_IN_MONTH: 'Number of months credits to be assigned to the user',
};


export const COUPONS_DROPDOWNS = {
  CLEAROUT: {
    discount: 'Discount',
    standard: 'Standard',
    bonus: 'Bonus'
  },
  CLEAROUTPHONE: {
    discount: 'Discount',
    standard: 'Standard',
  },
}
export const ACTION_TYPES = {
  domain_blocked: 'Domain Blocked',
  domain_unblocked: 'Domain Unblocked',
  email_denied: 'Email Denied',
  email_whitelisted: 'Email Whitelisted',
  email_delisted: 'Email Delisted',
  ip_blocked: 'IP Blocked',
  ip_unblocked: 'IP Unblocked',
  country_blocked: 'Country Blocked',
  matomo_blocked: 'Matomo Blocked',
  captcha_blocked: 'Captcha Blocked'
}

export const ACTIONS = {
  signup_abuse: 'Signup Abuse',
  signup_activation_abuse: 'Signup Activation Abuse',
  email_edit_abuse: 'Email Edit Abuse'
}

export const RENEWAL_UNITS = {
  month: {
    short_name: 'mo',
    name: 'Monthly',
    patterns: [
      {
        unit: 'month',
        count: 1
      },
      {
        unit: 'day',
        count: 30
      }
    ]
  },
  year: {
    short_name: 'yr',
    name: 'Yearly',
    patterns: [
      {
        unit: 'year',
        count: 1
      },
      {
        unit: 'day',
        count: 360
      },
      {
        unit: 'day',
        count: 365
      }
    ]
  },
  day: {
    short_name: 'day',
    name: 'Daily',
    patterns: [
      {
        unit: 'day',
        count: 1
      }
    ]
  },
  week: {
    short_name: 'week',
    name: 'Weekly',
    patterns: [
      {
        unit: 'week',
        count: 1
      }
    ]
  }
}