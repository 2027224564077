import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import ApiService from '../../helpers/apiservice';
import * as Constants from '../../helpers/constants'
import showToastNotification from '../widgets/toastify';

const initialState = {
  reason: '',
  showModal: false,
}

function UpdateMemberStatus({ co_user_id, getUserInfo, userData }) {
  const [state, setState] = useState(initialState)
  const id = useSelector((state) => state.user.id);

  const handleDescChange = (e) => {
    setState(prevState => ({ ...prevState, reason: e.target.value }))
  }

  const handleSaveChanges = () => {
    let body = {
      reason: state.reason,
      co_user_id: co_user_id,
      user_id: id,
      status: userData.account_status,
      co_org_id: userData.org_id
    }

    ApiService.accountStatusUpdateClearoutUser(body)
      .then((response) => {
        if (response.data && response.data.status === Constants.CO_API.SUCCESS) {
          showToastNotification({
            message: 'Please check organization seat availability',
            isSuccess: false,
          });
        } else if (response.status === Constants.CO_API.FAILED) {
          let notificationMessage = 'Member status has changed to live';
          if (userData.user_type === 'organization' && userData.user_role !== 'owner' && userData.account_status === 'removed') {
            notificationMessage = 'Member status has changed to live'
          } else {
            notificationMessage = 'Member status has changed to removed'
          }
          showToastNotification({
            message: notificationMessage,
            isSuccess: true,
          });
          handleCloseModal()
          getUserInfo()
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const handleShowModal = () => {
    setState(prevState => ({ ...prevState, showModal: true }));
  };

  const handleCloseModal = () => setState(initialState)

  const memstatus = userData.user_type === 'organization' && userData.user_role !== 'owner' && userData.account_status === 'removed'

  const getStatusButtons = () => {
    let btn = ''
    if (memstatus) {
      btn = 'change to live'
    } else {
      btn = 'Change to remove'
    }
    return btn
  }

  const getModaltext = () => {
    let text = ''
    if (memstatus) {
      text = 'Are you want to change the organization member status to Live ?'
    } else {
      text = 'Are you want to change the organization member status to Removed ?'
    }
    return text
  }

  const getPlaceholdertext = () => {
    let text = ''
    if (memstatus) {
      text = 'Please enter reason to know adding this member to organization'
    } else {
      text = 'Please enter reason to know removing this member from organization'
    }
    return text
  }

  return (
    <>
      <Button
        onClick={handleShowModal}
        variant='outline-primary'
        size="sm"
      >
        {getStatusButtons()}
      </Button>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>Update Member Status - {userData.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <p className='fw-bold fs-6'>{getModaltext()} </p>
          <Form>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-3'>
                  <Form.Label className='w-100 py-2'>Enter Reason :</Form.Label>
                </div>
                <div className='col-md-9'>
                  <Form.Control
                    as="textarea"
                    placeholder={getPlaceholdertext()}
                    value={state.reason}
                    onChange={handleDescChange}
                    maxLength={50}
                  />
                </div>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={handleSaveChanges}
            disabled={!state.reason}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateMemberStatus;
