import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, NavDropdown, Navbar } from 'react-bootstrap'

import { logoutSuccess } from './../../reducers/userSlice'
const coLogoLarge = require('./../../static/img/365x65-white.png')
// const coLogoLarge = require('./../../static/img/co-crm_logo.png')
const coLogoMini = require('./../../static/img/CO-EU.png')
const defaultImage = require('./../../static/img/user.png')

const Header = ({ toggle, isSideBarOpen }) => {

  const userData = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const { name: userName } = userData

  const onSignOutClick = (e) => {
    e.preventDefault()

    dispatch(logoutSuccess())
    window.location.reload()
    localStorage.clear()
  }

  const getNavbarTitle = () => {
    return (
      <>
        <img
          className="rounded-circle"
          src={defaultImage}
          alt="User Pic"
          width="32px"
          height="32px"
        />
        <span className="fw-bold text-white">{userName}</span>
      </>
    )
  }

  return (
    <header
      id="header"
      className="main-header"
    >
      <Navbar
        className="d-flex justify-content-between p-1"
        fixed="top"
      >
        <div className="ms-1 d-flex align-items-center">
          <Navbar.Brand href="/">
            <img
              className={'logo' + (isSideBarOpen ? '' : ' mini')}
              src={isSideBarOpen ? coLogoLarge : coLogoMini}
              alt="Clearout Logo"
              aria-label="Clearout Logo"
            />
          </Navbar.Brand>
          <Button 
            className="bg-transparent" 
            onClick={toggle}
          >
            <span className="sr-only">Toggle sidebar</span>
            <i className="fa-solid fa-bars fs-5"></i>
          </Button>
        </div>
        <NavDropdown
          title={getNavbarTitle()}
          id="header-dropdown"
          className="me-1"
          align="end"
        >
          <NavDropdown.Item
            onClick={onSignOutClick}
          >
            Sign Out
          </NavDropdown.Item>
        </NavDropdown>
      </Navbar>
    </header>
  )

}

export default Header