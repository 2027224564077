import React, { useEffect, useState } from 'react'

import ApiService from '../../../../helpers/apiservice'
import * as Constants from '../../../../helpers/constants'

import CurrencyDropdown from '../dropdowns/currency_dropdown'
import ProdcutIntervalDropdowns from '../dropdowns/interval_dropdowns'
import LimitsIntervalDropdowns from '../dropdowns/limit_interval_dropdown'
import ProductAmountInput from '../inputs/amount_input'
import ProductIsCustomCheckbox from '../checks/is_custom_checkbox'
import ProductIsRecurringCheckbox from '../checks/recurring_checkbox'
import AddonProductNamesDropdown from '../dropdowns/addon_product_dropdown'
import CustomAddonDropdownOptions from '../dropdowns/custom_addon_dropdown'

const initialState = {
  addonNameOptions: [],
  productInfo: ''
}

const CreateAddonProduct = ({ onStateChange, ...props }) => {

  const [state, setState] = useState(initialState)

  useEffect(() => {
    getAddonProductNames()
  }, [])

  const getAddonProductNames = () => {
    ApiService.getCoAddonProductInfo()
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          setState((prevState) => ({
            ...prevState,
            addonNameOptions: response.data.data.list
          }))
        }
      })
      .catch((error) => {
        console.log(error)
      });
  }

  const onSelectedProduct = (selectedOption) => {
    setState((prevState) => ({
      ...prevState,
      productInfo: selectedOption
    }))

    onStateChange('product_name', selectedOption.value)
  }

  return (
    <div className="addon-product-form">
      <AddonProductNamesDropdown
        addonNameOptions={state.addonNameOptions}
        productInfo={state.productInfo}
        onSelectedProduct={onSelectedProduct}
      />
      <ProdcutIntervalDropdowns onStateChange={onStateChange} />
      <LimitsIntervalDropdowns onStateChange={onStateChange} />
      <div className="addon-amount-n-currency">
        <CurrencyDropdown onStateChange={onStateChange} />
        <ProductAmountInput
          addonNameOptions={state.addonNameOptions}
          productInfo={state.productInfo}
          onStateChange={onStateChange}
          {...props}
        />
      </div>
      <CustomAddonDropdownOptions onStateChange={onStateChange} />
      <div className="addon-custom-n-recurring">
        <ProductIsCustomCheckbox onStateChange={onStateChange} />
        <ProductIsRecurringCheckbox onStateChange={onStateChange} />
      </div>
    </div>
  )
}

export default CreateAddonProduct