import React from 'react';
import { Outlet } from 'react-router-dom';

const MainContent = () => {
  return (
    <div
      className="content-wrapper"
      id="mainContent"
    >
      <Outlet />
    </div>
  )
}

export default MainContent