import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'

import ProductListTable from '../../tables/product/product_list'
import ApiService from '../../../helpers/apiservice'
import FiltersComponent from '../../widgets/filters'

import * as Constants from './../../../helpers/constants'
import * as FiltersConstants from './../../widgets/filters/constants'

import './../../../static/css/products/product_list.css'

const initialStates = {
  prodList: [],
  filters: {
    renewal_interval: {
      filter_label: 'Billed',
      label: 'All',
      value: 'all'
    },
    status: {
      filter_label: 'Product Status',
      label: 'All',
      value: 'all'
    },
    currency: {
      filter_label: 'Currency',
      label: 'All',
      value: 'all'
    },

  }
}


const ProductList = ({ client }) => {
  const [state, setState] = useState(initialStates)

  useEffect(() => {
    getProductList()
  }, [])


  const onSetFilters = (filters) => {
    setState((prevState) => ({
      ...prevState,
      filters
    }))
  }

  const getProductList = () => {
    let queryParams = {
      limit: 100,
      skip: 0,
      total_required: true,
      filters: JSON.stringify({
        status: 'active',
        // is_custom: true
      })
    }

    ApiService.getCoProductList(queryParams)
      .then((response) => {
        if (response && response.status === Constants.CO_API.SUCCESS) {
          let prodList = []

          if (!_.isEmpty(response.data.data.lists)) {
            prodList = response.data.data.lists.map(prod => { prod.id = prod._id; return prod })
          }
          setState((prevState) => ({
            ...prevState,
            prodList
          }))
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const columns = [
    {
      label: 'Display Name',
      renderCell: (product) => {
        return product.display_name
      },
    },
    {
      label: 'Plan Type',
      renderCell: (product) => {
        return _.startCase(product.type)
      }
    },
    {
      label: 'Renewal',
      renderCell: (product) => {
        if (!product.renewal)
          return null

        return (
          <div className='d-flex flex-column'>
            <span>
              <span>Renewal Unit: </span>
              <span>{_.startCase(product.renewal.unit)}</span>
            </span>
            <span>
              <span>Rnewal Count: </span>
              <span>{product.renewal.count}</span>
            </span>
            <span>
              <span>Renewal Times: </span>
              <span>{product.renewal.times || 'Infinity'}</span>
            </span>
          </div>
        )
      }
    },
    {
      label: 'Price',
      renderCell: (product) => {
        let priceInUSD = product.price.total_price
        let priceInINR = product.price.total_price
        let exchangeRate = product.price.exchange_rate

        if (product.price.currency === 'INR')
          priceInUSD = product.price.price_in_usd

        return (
          <div className='d-flex flex-column'>
            <span>
              <span>Price in USD: </span>
              <span>{priceInUSD}</span>
            </span>
            {
              product.price.currency === 'INR'
                ?
                <>
                  <span>
                    <span>Price in INR: </span>
                    <span>{priceInINR}</span>
                  </span>
                  <span className='text-muted'>
                    <small>Exchange Rate: </small>
                    <small>{exchangeRate}</small>
                  </span>
                </>
                : null
            }
          </div>
        )
      }
    },
    {
      label: 'Currency',
      renderCell: (product) => {
        return product.price.currency
      }
    },
    {
      label: 'Custom',
      renderCell: (product) => {
        return product.is_custom.toString().toUpperCase()
      }
    },
    {
      label: 'Custom Users',
      renderCell: (product) => {
        if (product.is_custom) {
          if (!_.isEmpty(product.users))
            return (
              <div className='d-flex flex-column'>
                {
                  product.users.map((user_id, idx) => (
                    <a
                      key={idx}
                      className='text-decoration-none'
                      href={`/dashboard/clearout_individual_user_info?user_id=${user_id}&user_type=individual`}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {user_id}
                    </a>
                  )
                  )
                }
              </div>
            )
          else
            return (
              <small className='text-muted'>NO USER ATTACHED</small>
            )
        }

        return null
      }
    },
    // {
    //   label: 'Version',
    //   renderCell: (product) => {
    //     return product.version
    //   }
    // },
    {
      label: 'Created',
      renderCell: (product) => {
        return (
          <div className="d-flex flex-column">
            <span>{product.created_by}</span>
            <span>{moment(product.created_on).format('DD-MMM-YYYY @ h:mm:ss a')}</span>
          </div>
        )
      }
    }
  ]

  return (
    <div>
      <div className="page-header mb-2">
        <h1 className='m-0 header'>
          {Constants.CLIENTS[client.toUpperCase()].DISPLAY_NAME} Plan List
        </h1>
      </div>
      <div className="page-content">
        <FiltersComponent
          onSetFilters={onSetFilters}
          filterState={state.filters}
          // onSearch={onSearch}
          // clearSearch={clearSearch}
          // searchValue={state.searchText}
          filters={FiltersConstants.PRODUCT_LIST_PAGE[client].filters}
          filtersToInclude={FiltersConstants.FILTERS_TO_INCLUDE[client].PRODUCT_LIST_PAGE}
          // skipFilter={['date_range', 'org_filter']}
          resetFilters={FiltersConstants.PRODUCT_LIST_PAGE[client].onResetFilters}
          className={'product-filters'}
        />
        <ProductListTable
          data={state.prodList}
          columns={columns}
        />
      </div>
    </div>
  )
}

export default ProductList