import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import ApiService from '../../../../helpers/apiservice';
import showToastNotification from '../../../widgets/toastify'
import * as Constants from '../../../../helpers/constants'
import * as UserInfoPageApiHelpers from '../../helpers/user_info_apis'

const initialState = {
  reason: 'Suspicious Behaviour ',
  isApiCallInProgress: false,
  showModal: false,
};

function BlockUserModel({ userData, getUserInfo, client }) {
  const [state, setState] = useState(initialState);
  const id = useSelector((state) => state.user.id);
  const userName = useSelector(state => state.user.name);

  const handleReasonChange = (e) => {
    setState(prevState => ({ ...prevState, reason: e.target.value }))
  }

  const handleSaveChanges = () => {
    if (state.isApiCallInProgress) {
      return;
    }
    let requestBody = {
      co_user_id: userData.user_id,
      cop_user_id: userData.user_id,
      user_id: id,
      reason: state.reason,
      client_secret: Constants.CLIENT_SECRET,
      crm_user_data: {
        name: userName,
        user_id: id,
      },
    };
    let body = _.pick(requestBody, UserInfoPageApiHelpers.API_HELPERS[client].BLOCK_USER.API_BODY)

    setState(prevState => ({ ...prevState, isApiCallInProgress: true }));
    ApiService[UserInfoPageApiHelpers.API_HELPERS[client].BLOCK_USER.API_NAME](body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          showToastNotification({
            message: response.message,
            isSuccess: false,
          });
        } else if (response.status === Constants.CO_API.SUCCESS) {
          getUserInfo();
          handleCloseModal()
          showToastNotification({
            message: response.message,
            isSuccess: true,
          });
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setState(prevState => ({ ...prevState, isApiCallInProgress: false }));
      });
  };

  const handleShowModal = () => {
    setState(prevState => ({ ...prevState, showModal: true }));
  };

  const handleCloseModal = () => setState(initialState);

  return (
    <>
      <p
        onClick={handleShowModal}
        className='m-0 text-wrap'
      >
        Block User
      </p>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>
            Are You Sure want to block {userData?.name}&apos;s account
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <Form>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-2'>
                  <Form.Label className='w-100 py-2'>Reason: </Form.Label>
                </div>
                <div className='col-md-10'>
                  <Form.Control
                    type='text'
                    value={state.reason}
                    onChange={handleReasonChange}
                  />
                </div>
              </Form.Group>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='danger'
            onClick={handleSaveChanges}
            disabled={state.isApiCallInProgress}
          >
            Yes!!! Block Account
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default BlockUserModel;
