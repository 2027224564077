import React from 'react'
import RemoveEmailFromCache from './remove_email_from_cache'
import UpdateDisposableDomain from './update_disposable_domain'
import RadarSettings from './radar/settings'

import '../../static/css/miscellaneous.css'

const MiscellaneousApi = () => {
  return (
    <div>
      <h1 className='fs-3'>Miscellaneous API</h1>
      <div className='d-flex gap-2'>
        <RemoveEmailFromCache />
        <UpdateDisposableDomain />
        <RadarSettings />
      </div>
    </div>
  )
}

export default MiscellaneousApi 
