import React, { useState } from 'react';
import { Modal, Button, Form, Badge } from 'react-bootstrap';
import ApiService from '../../helpers/apiservice';
import * as Constants from '../../helpers/constants'
import { useSelector } from 'react-redux';
import showToastNotification from '../widgets/toastify';

const initialState = {
  showModal: false,
  domainsInput: '',
  domains: []
}

const UpdateDisposableDomain = () => {
  const [state, setState] = useState(initialState)
  const id = useSelector(state => state.user.id);

  const handleInputChange = (e) => {
    const domainsArray = e.target.value.split(/[,\s\n]+/);
    const filteredDomainsArray = domainsArray.filter((domain) => domain.trim() !== '');
    setState((prevState) => ({ ...prevState, domainsInput: e.target.value, domains: filteredDomainsArray }));
  };

  const handleBlur = () => {
    const domainsArray = state.domainsInput.split(/[,\s\n]+/);
    const filteredDomainsArray = domainsArray.filter((domain) => domain.trim() !== '');
    setState((prevState) => ({ ...prevState, domains: filteredDomainsArray }));
  };

  const removeDomain = (index) => {
    const updatedDomains = [...state.domains];
    updatedDomains.splice(index, 1);
    setState((prevState) => ({ ...prevState, domains: updatedDomains, domainsInput: updatedDomains.join(',') }));
  };

  const handleSaveChanges = () => {
    let body = {
      domains: state.domains,
      user_id: id,
    };

    ApiService.updateDisposableDomains(body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          showToastNotification({
            message: response.data.message,
            isSuccess: false,
          });
        } else if (response.status === Constants.CO_API.SUCCESS) {
          showToastNotification({
            message: response.data.message || 'Updated successfully',
            isSuccess: true,
          });
          handleCloseModal()
        }
      })
      .catch((error) => {
        console.log(error)
      });
  };

  const handleShowModal = () => {
    setState(prevState => ({ ...prevState, showModal: true }));
  };

  const handleCloseModal = () => setState(initialState);

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShowModal}
      >
        Update Disposable Domain
      </Button>
      <Modal
        show={state.showModal}
        onHide={handleCloseModal}
        size='lg'
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title className='fs-6 fw-bold'>Update Disposable Domain</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body-bg-color'>
          <Form>
            <div className='row'>
              <Form.Group
                className='d-flex'
              >
                <div className='col-md-2'>
                  <Form.Label className='w-100 py-2'>Enter Domains:</Form.Label>
                </div>
                <div className='col-md-10'>
                  <Form.Control
                    type="text"
                    placeholder="Enter Domains"
                    value={state.domainsInput}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Form.Group>
            </div>
          </Form>
          <div className='d-flex gap-1 flex-wrap'>
            {state.domains.map((domain, index) => (
              <Badge
                key={index}
                className="badges"
              >
                {domain}
                <span
                  className='ms-2'
                  onClick={() => removeDomain(index)}
                >
                  &#x2716;
                </span>
              </Badge>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={handleSaveChanges}
            disabled={state.domains.length === 0}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateDisposableDomain;
