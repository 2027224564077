import React from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom';

import workinProgress from '../widgets/work_in_progress_toastify';
import * as IndividualUserConstants from './helpers/constant'
import CustomOverlayTrigger from '../widgets/overlay_trigger';

import './../../static/css/profile.css';

const QuickLinks = ({ client, userData }) => {
  return (
    <>
      {_.includes(IndividualUserConstants.QUICK_LINKS[client], 'user_activities') &&
        <CustomOverlayTrigger
          content="User Activities"
          placement="bottom"
        >
          <Link
            to={`/dashboard/clearout_individual_user_activity?user_id=${userData._id}`}
            target='blank'
          >
            <img
              src={require('../../static/img/user_activity_icon.png')}
              alt='user-activity'
              width='22'
              height='22'
            />
          </Link>
        </CustomOverlayTrigger>
      }
      {_.includes(IndividualUserConstants.QUICK_LINKS[client], 'email_verifier_lists') &&
        <CustomOverlayTrigger
          content="Email Verifier List"
          placement="bottom"
        >
          <Link
            to={`${OLD_CRM_URL}dashboard/clearout_lists?org_id=${userData._id}&org_name=${userData.name}`}
            target='blank'
          >
            <img
              src={require('../../static/img/email_verifier.png')}
              alt='email-verifier-list'
              width='22'
              height='22'
            />
          </Link>
        </CustomOverlayTrigger>
      }
      {_.includes(IndividualUserConstants.QUICK_LINKS[client], 'email_finder_lists') &&
        <CustomOverlayTrigger
          content="Email Finder List"
          placement="bottom"
        >
          <Link
            to={`${OLD_CRM_URL}dashboard/clearout_emailfinder_lists?org_id=${userData._id}&org_name=${userData.name}`}
            target='blank'
          >
            <img
              src={require('../../static/img/email_finder.png')}
              alt='email-finder-list'
              width='22'
              height='22'
            />
          </Link>
        </CustomOverlayTrigger>
      }
      {_.includes(IndividualUserConstants.QUICK_LINKS[client], 'prospect_lists') &&
        <CustomOverlayTrigger
          content="Prospect List"
          placement="bottom"
        >
          <Link
            to={`${OLD_CRM_URL}dashboard/clearout_prospect/lists?org_id=${userData._id}&org_name=${userData.name}`}
            target='blank'
          >
            <img
              src={require('../../static/img/prospect.png')}
              alt='prospect-list'
              width='22'
              height='22'
            />
          </Link>
        </CustomOverlayTrigger>
      }
      {_.includes(IndividualUserConstants.QUICK_LINKS[client], 'clearout_phone_lists') &&
        <CustomOverlayTrigger
          content="Clearoutphone List"
          placement="bottom"
        >
          <Link
            onClick={workinProgress}
          >
            <img
              src={require('../../static/img/phone_number_list.png')}
              alt='clearoutphone-list'
              width='22'
              height='22'
            />
          </Link>
        </CustomOverlayTrigger>
      }
    </>
  )
}

export default QuickLinks;
