import React, { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'

import ApiService from '../../../../../helpers/apiservice'
import showToastNotification from '../../../../widgets/toastify'
import UserPlansAndActiveSubscriptionsTable from '../../../../tables/plans/user_plans_and_active_subs_list'
import FiltersComponent from '../../../../widgets/filters'

import * as Constants from '../../../../../helpers/constants'
import * as UserInfoPageApiHelpers from '../../../helpers/user_info_apis'
import * as FiltersConstants from './../../../../widgets/filters/constants'

const initialState = {
  userPlansAndSubs: [],
  filters: {
    billed: {
      filter_label: 'Billed',
      label: 'All',
      value: 'all'
    },
    currency: {
      filter_label: 'Currency',
      label: 'All',
      value: 'all'
    },
    subscription_status: {
      filter_label: 'Subscription Status',
      label: 'All',
      value: 'all'
    },
  }
}

const formatFilters = (client, filters) => {
  let formattedFilter = {}

  _.forEach(filters, (f) => {

    switch (f.filter_label) {
      case 'Billed':
      case 'Subscription Status':
      case 'Currency':
      case 'Plan Type':
      case 'Customized':
      case 'Product Type':
      case 'Next Renewal On': {
        let filterObj = _.find(FiltersConstants.PLANS_TAB[client].filters, { name: f.filter_label })
        if (f.value !== 'all')
          formattedFilter[filterObj.value] = f.value
      }
        break

    }
  })
  return formattedFilter
}

const UserPlansAndActiveSubs = ({ client, userData }) => {

  const [state, setState] = useState(initialState)

  const onSetFilters = (filters) => {
    setState((prevState) => ({
      ...prevState,
      filters
    }))
  }

  const getUsersPlansAndSubscriptions = useCallback(() => {
    let requestBody = {
      co_user_id: userData.user_id,
      filters: JSON.stringify(formatFilters(client, state.filters))
    }
    let body = _.pick(requestBody, UserInfoPageApiHelpers.API_HELPERS[client].USER_PLANS_AND_ACTIVE_SUBSCRIPTIONS.API_BODY)
    ApiService[UserInfoPageApiHelpers.API_HELPERS[client].USER_PLANS_AND_ACTIVE_SUBSCRIPTIONS.API_NAME](body)
      .then((response) => {
        if (response.status === Constants.CO_API.SUCCESS) {
          setState(prevState => ({
            ...prevState,
            userPlansAndSubs: response.data
          }))
        } else {
          let message = response.message || 'Something went wrong'
          showToastNotification({
            message,
            isSuccess: false,
          });
        }
      })
      .catch((err) => {
        let message = err.message || 'Something went wrong'
        showToastNotification({
          message,
          isSuccess: false,
        });
      })
  }, [userData.user_id, client, state.filters])

  // mount
  useEffect(() => {
    getUsersPlansAndSubscriptions()
  }, [getUsersPlansAndSubscriptions])

  const columns = [
    {
      label: 'Display Name',
      renderCell: (product) => {
        return product.display_name
      },
    },
    {
      label: 'Status',
      renderCell: (product) => {
        let status = 'inactive'

        if (product.user_product && product.user_product.status)
          status = product.user_product.status

        return _.startCase(status)
      }
    },
    {
      label: 'Price',
      renderCell: (product) => {
        let priceInUSD = product.price.total_price
        let priceInINR = product.price.total_price
        let exchangeRate = product.price.exchange_rate

        if (product.price.currency === 'INR')
          priceInUSD = product.price.price_in_usd

        if (product.user_product) {
          let amountDetails = product.user_product.purchase_details.amount_details

          priceInUSD = amountDetails.price_in_usd
          priceInINR = amountDetails.total_price
          exchangeRate = amountDetails.exchange_rate
        }

        return (
          <div className='d-flex flex-column'>
            <span>
              <span>Price in USD: </span>
              <span>{priceInUSD}</span>
            </span>
            {
              product.price.currency === 'INR'
                ?
                <>
                  <span>
                    <span>Price in INR: </span>
                    <span>{priceInINR}</span>
                  </span>
                  <span className='text-muted'>
                    <small>Exchange Rate: </small>
                    <small>{exchangeRate}</small>
                  </span>
                </>
                : null
            }
          </div>
        )
      }
    },
    {
      label: 'Billing',
      renderCell: (product) => {
        if (!product.renewal)
          return <span>One-Time</span>

        return (
          <div className='d-flex flex-column'>
            <span>
              <span>{product.renewal.count} {_.startCase(product.renewal.unit)}</span>
            </span>
            <small className='text-muted'>
              (
              <span>Times: </span>
              <span>{product.renewal.times || 'Infinity'}</span>
              )
            </small>
          </div>
        )
      }
    },
    {
      label: 'Next Renewal On',
      renderCell: (product) => {
        let nextRenewalOn = '-'

        if (product.user_product) {
          nextRenewalOn = moment(product.user_product.next_renewal_on).format('Do MMMM YYYY, h:mm:ss A')
        }
        return (
          <span className='text-wrap'>
            {nextRenewalOn}
          </span>
        )
      }
    },
    {
      label: 'Next Allocation On',
      renderCell: (product) => {
        let nextAllocationOn = '-'

        if (product.next_allocation_date) {
          nextAllocationOn = moment(product.next_allocation_date).format('Do MMMM YYYY, h:mm:ss A')
        }
        return (
          <span className='text-wrap'>
            {nextAllocationOn}
          </span>
        )
      }
    },
    {
      label: 'Action',
      renderCell: (product) => {
        let is_custom = product.is_custom

        let cancel = false
        if (product.user_product && product.user_product.status === 'active') {
          cancel = true
        }
        return (
          <span className='d-flex flex-column text-wrap'>
            {
              cancel
                ? <span>Cancel Subs</span>
                : null
            }
            {
              is_custom
                ? <span>Do Something</span>
                : null
            }
          </span>
        )
      }
    },
    {
      label: 'Customized',
      renderCell: (product) => {
        let customized = 'no'
        if (product.is_custom)
          customized = 'yes'

        return _.startCase(customized)
      }
    },
    {
      label: 'Type',
      renderCell: (product) => {
        return _.startCase(product.type)
      }
    }
  ]

  return (
    <div className='d-flex flex-column gap-1'>
      <FiltersComponent
        onSetFilters={onSetFilters}
        filterState={state.filters}
        // onSearch={onSearch}
        // clearSearch={clearSearch}
        // searchValue={state.searchText}
        filters={FiltersConstants.PLANS_TAB[client].filters}
        filtersToInclude={FiltersConstants.FILTERS_TO_INCLUDE[client].PLANS_TAB}
        // skipFilter={['date_range', 'org_filter']}
        resetFilters={FiltersConstants.PLANS_TAB[client].onResetFilters}
        className={'plan-filters'}
      />
      <UserPlansAndActiveSubscriptionsTable
        data={state.userPlansAndSubs}
        columns={columns}
      />
    </div>
  )
}

export default UserPlansAndActiveSubs