import React, { useState, useEffect, useCallback, useRef } from 'react';
import _ from 'lodash';
import Dropdown from 'react-bootstrap/Dropdown';
import { Form } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import ApiService from '../../helpers/apiservice';
import CreateCoupon from './create_coupon';
import * as Constants from '../../helpers/constants';
import * as UserListPageApiHelpers from '../user_list_page/helpers/user_list_apis';
import CouponsListTable from './coupons_table';
import * as Helpers from '../../helpers/helper';

const initialState = {
  data: [],
  isLoading: false,
  searchText: '',
  tableDataLength: 0,
  sorting: [],
  dropdowns: 'discount',
};

const Coupons = ({ client, coupon }) => {
  const [state, setState] = useState(initialState);
  const location = useLocation();
  const paths = location.pathname.split('/');
  const couponId = paths[paths.length - 1];
  const abortControllerRef = useRef(null); // Use ref to store the controller across renders

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort('canceled');
      }
      setState(initialState);
    };
  }, []);

  const getCouponListApi = useCallback(
    (requestBody, signal = null) => {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const body = _.pick(requestBody, UserListPageApiHelpers.API_HELPERS[client].COUPONS_LIST.API_BODY);

      ApiService[UserListPageApiHelpers.API_HELPERS[client].COUPONS_LIST.API_NAME](body, signal)
        .then((response) => {
          if (response && response.status === Constants.CO_API.SUCCESS) {
            setState((prevState) => ({
              ...prevState,
              data: response.data.data,
              tableDataLength: Math.ceil(response.data.count / body.limit),
            }));
          }
        })
        .catch((error) => {
          if (error.name !== 'AbortError') {
            console.error(error);
          }
        })
        .finally(() => {
          setState((prevState) => ({ ...prevState, isLoading: false }));
        });
    },
    [client]
  );

  const fetchCoupons = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort('canceled');
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    let sort = _.isEmpty(state.sorting) ? { created_on: 'desc' } : state.sorting;

    if (Array.isArray(sort)) {
      sort = { [sort[0].id]: sort[0].desc ? 'desc' : 'asc' };
    }

    const body = {
      skip: 0,
      limit: 50,
      search: coupon === 'coupon_search' ? couponId : state.searchText || state.dropdowns,
      sort,
      client_secret: Constants.CLIENT_SECRET,
    };

    getCouponListApi(body, controller.signal);
  }, [coupon, couponId, getCouponListApi, state.dropdowns, state.sorting, state.searchText]);

  useEffect(() => {
    fetchCoupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCoupons]);

  const getClearoutUsers = (skip, limit = 50, searchText, sorting) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort('canceled');
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    let sortingData = { created_on: 'desc' };

    if (sorting && sorting.length > 0) {
      sortingData = { [sorting[0].id]: sorting[0].desc ? 'desc' : 'asc' };
    }

    const body = {
      skip: skip || 0,
      limit: limit || 50,
      search: searchText || state.searchText,
      sort: sortingData || state.sorting,
      client_secret: Constants.CLIENT_SECRET,
    };

    getCouponListApi(body, controller.signal);
  };

  const handleDropdown = (eventKey) => {
    setState((prevState) => ({ ...prevState, dropdowns: eventKey }));
  };

  const onSearchChange = (e) => {
    const value = e.target.value;
    setState((prevState) => ({ ...prevState, searchText: value }));
    getClearoutUsers(0, 50, value, state.sorting);
  };

  const clientDisplayName = Constants.CLIENTS[client.toUpperCase()].DISPLAY_NAME;

  return (
    <div className='overflow-auto'>
      <div className='d-flex justify-content-between'>
        <h1 className='m-0 header'>
          {clientDisplayName} Coupons
        </h1>
        <div className='mb-2'>
          <CreateCoupon
            getClearoutUsers={getClearoutUsers}
            client={client}
            dropdowns={state.dropdowns}
          />
        </div>
      </div>
      <div>
        <Dropdown onSelect={handleDropdown}>
          <Dropdown.Toggle
            variant="secondary"
            id="coupon-dropdown"
          >
            {Helpers.capitalizeFirstLetter(state.dropdowns)}
          </Dropdown.Toggle>
          <Dropdown.Menu className='coupon-dropdown'>
            <Dropdown.Item eventKey='discount'>{Constants.COUPONS_DROPDOWNS[client.toUpperCase()].discount}</Dropdown.Item>
            <Dropdown.Item eventKey='standard'>{Constants.COUPONS_DROPDOWNS[client.toUpperCase()].standard}</Dropdown.Item>
            <Dropdown.Item eventKey='bonus'>{Constants.COUPONS_DROPDOWNS[client.toUpperCase()].bonus}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className='d-flex justify-content-end py-2'>
        <Form.Control
          size="sm"
          type="search"
          placeholder="Search..."
          className='w-25'
          value={state.searchText}
          onChange={onSearchChange}
        />
      </div>
      <div>
        <CouponsListTable
          client={client}
          getClearoutUsers={getClearoutUsers}
          data={state.data}
          isLoading={state.isLoading}
          sorting={state.sorting}
          searchText={state.searchText}
          filters={state.filters}
          tableDataLength={state.tableDataLength}
          dropdowns={state.dropdowns}
        />
      </div>
    </div>
  );
};

export default Coupons;
