import React, { useEffect, useState } from 'react'
import { Button, FloatingLabel, Form, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import ApiService from '../../helpers/apiservice'
import * as Constants from '../../helpers/constants'
import { loginSuccess } from '../../reducers/userSlice'

import './../../static/css/login.css'
const coLogo = require('./../../static/img/365x65-white.png')

const initialState = {
  username: '',
  password: '',
  error: {}
}

const Login = () => {
  const [state, setState] = useState(initialState)
  const dispatch = useDispatch()

  // subscribing to all states
  useSelector((state) => localStorage.setItem(APP_NAME, JSON.stringify(state)))

  // reseting erors
  useEffect(() => {
    if (!_.isEmpty(state.error)) {
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState, error: {}
        }))
      }, 5000)
    }
  }, [state.error])

  const onChange = (e) => {
    let target = e.target
    setState((prevState) => ({ ...prevState, [target.id]: target.value }))
  }

  const validateForm = () => {
    let errors = {}
    console.log(state)
    if (!state.username ||
      (state.username && !state.username.trim())) {
      errors['username'] = 'Please enter a valid username'
    }

    if (!state.password ||
      (state.password && !state.password.trim())) {
      errors['password'] = 'Please enter a valid password'
    }

    if (errors && Object.keys(errors).length) {
      setState((prevState) => ({ ...prevState, error: errors }))
      return false
    }

    return true
  }

  const onFormSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (validateForm()) {
      let body = {
        username: state.username.trim(),
        password: state.password.trim()
      }
      ApiService
        .login(body)
        .then((res) => {
          if (res && res.status === Constants.CO_API.SUCCESS) {
            dispatch(loginSuccess(res.data))
          } else {
            setState((prevState) => ({
              ...prevState, error: { message: 'Invalid Username or Password' }
            }))
          }
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  return (
    <div className='login-box'>
      <div className='login-img'>
        <a href='/login'>
          <Image src={coLogo} />
        </a>
        <div className='pt-3 m-auto p-2 pb-4 rounded-1 bg-transparent'>
          <Form
            className='d-flex row-gap-3 flex-column'
            onSubmit={onFormSubmit}
          >
            <Form.Group>
              <FloatingLabel label='Username'>
                <Form.Control
                  type='text'
                  id='username'
                  placeholder='username'
                  value={state.username}
                  required
                  autoFocus
                  onChange={onChange}
                />
                <Form.Text className={state.error?.username ? 'text-danger' : 'd-none'}>
                  {state.error.username}
                </Form.Text>
              </FloatingLabel>
            </Form.Group>
            <Form.Group>
              <FloatingLabel label='Password'>
                <Form.Control
                  type='password'
                  id='password'
                  placeholder='password'
                  value={state.password}
                  required
                  onChange={onChange}
                />
                <Form.Text className={state.error?.password ? 'text-danger' : 'd-none'}>
                  {state.error.password}
                </Form.Text>
              </FloatingLabel>
            </Form.Group>
            <Form.Text className={state.error?.message ? 'text-danger' : 'd-none'}>
              {state.error.message}
            </Form.Text>
            <Button
              type='submit'
              className='align-self-end'
            >
              Sign In
            </Button>
          </Form>
        </div>
      </div>

    </div >
  )
}

export default Login