import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import _ from 'lodash'

import showToastNotification from './../../../../widgets/toastify'
import ApiService from './../../../../../helpers/apiservice'
import * as UserInfoPageApiHelpers from './../../../helpers/user_info_apis'
import * as Constants from './../../../../../helpers/constants'
import Toggle from 'react-toggle'

const initialState = {
  acap: false,
  alap: false
}

const PaymentSettingsModal = ({
  showModal,
  closeModal,
  getUserInfo,
  userData,
  client
}) => {
  initialState.acap = userData.payment.s.acap
  initialState.alap = userData.payment.s.alap

  const [state, setState] = useState(initialState)

  const updateUsersPaymentSettings = () => {

    let requestBody = {
      co_user_id: userData.user_id,
      acap: state.acap,
      alap: state.alap
    }

    let body = _.pick(requestBody, UserInfoPageApiHelpers.API_HELPERS[client].UPDATE_USERS_PAYMENT_SETTINGS.API_BODY)
    setState(prevState => ({ ...prevState, isApiCallInProgress: true }))
    ApiService[UserInfoPageApiHelpers.API_HELPERS[client].UPDATE_USERS_PAYMENT_SETTINGS.API_NAME](body)
      .then((response) => {
        if (response.status === Constants.CO_API.FAILED) {
          showToastNotification({
            message: response.message || 'Failed to udpate settings',
            isSuccess: false,
          })
        } else {
          getUserInfo()
          handleCloseModal()
          showToastNotification({
            message: response.message || 'Payment settings updated',
            isSuccess: true,
          })
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        setState(prevState => ({ ...prevState, isApiCallInProgress: false }))
      })
  }

  const handleCloseModal = () => {
    setState(initialState)
    closeModal()
  }

  const handleToggleChange = (e) => {
    let name = e.target.name
    setState((prevState) => ({
      ...prevState,
      [name]: !prevState[name]
    }))
  }

  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title className='fs-6 fw-bold'>
          Change Payment Settings for {userData.name || userData.email}&apos;s account
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='modal-body-bg-color'>
        <div className='d-flex flex-column gap-2'>
          <div className='payment-settings-toggle acap'>
            <label className='payment-toggle-label'>
              Allow Credit Addon Purchase:&nbsp;
            </label>
            <Toggle
              className='acap-toggle'
              checked={state.acap}
              onChange={handleToggleChange}
              name='acap'
            />
          </div>
          <div className='payment-settings-toggle alap'>
            <label className='payment-toggle-label'>
              Allow Limit Addon Purchase:&nbsp;
            </label>
            <Toggle
              className='alap-toggle'
              checked={state.alap}
              onChange={handleToggleChange}
              name='alap'
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={updateUsersPaymentSettings}
          disabled={state.isApiCallInProgress}
        >
          Update Settings
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default PaymentSettingsModal